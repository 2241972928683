import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FiChevronLeft, FiChevronRight, FiMessageSquare } from 'react-icons/fi';
import { getTestimonials } from '../../firebase/firestore';
import { Testimonial } from '../../types';
import Card from '../../components/ui/Card';
import Button from '../../components/ui/Button';

const TestimonialsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderSection = styled.div`
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing.xl};
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
`;

const Heading = styled.h1`
  font-size: ${({ theme }) => theme.typography.fontSizes.heading};
  color: ${({ theme }) => theme.text.primary};
  margin-bottom: ${({ theme }) => theme.spacing.md};
  font-weight: 300;
  letter-spacing: -0.5px;
`;

const Subheading = styled.p`
  color: ${({ theme }) => theme.text.secondary};
  max-width: 800px;
  font-size: ${({ theme }) => theme.typography.fontSizes.lg};
  line-height: 1.6;
`;

// Carousel styled components
const CarouselContainer = styled.div`
  position: relative;
  margin-bottom: ${({ theme }) => theme.spacing.xl};
`;

const CarouselInner = styled.div`
  display: flex;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.borders.radius.md};
`;

const CarouselSlide = styled.div<{ active: boolean; total: number }>`
  flex: 0 0 100%;
  max-width: 100%;
  opacity: ${({ active }) => (active ? 1 : 0)};
  transform: translateX(${({ active, total }) => (active ? '0%' : '100%')});
  transition: all 0.5s ease;
`;

const CarouselControls = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.spacing.md};
`;

const CarouselIndicators = styled.div`
  display: flex;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.xs};
  margin-top: ${({ theme }) => theme.spacing.md};
`;

const CarouselIndicator = styled.button<{ active: boolean }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ active, theme }) => 
    active ? theme.text.accent : theme.border};
  border: none;
  transition: ${({ theme }) => theme.transitions.default};
  cursor: pointer;
  
  &:hover {
    background-color: ${({ active, theme }) => 
      active ? theme.text.accent : theme.text.secondary};
  }
`;

// Testimonial styled components
const FeaturedTestimonial = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.xl};
  height: 100%;
  
  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.xl};
  }
`;

const TestimonialImage = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: ${({ theme }) => theme.spacing.lg};
  flex-shrink: 0;
  align-self: center;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  @media (min-width: 768px) {
    margin-bottom: 0;
  }
`;

const TestimonialContent = styled.div`
  flex: 1;
`;

const TestimonialQuote = styled.div`
  font-size: ${({ theme }) => theme.typography.fontSizes.lg};
  font-style: italic;
  margin-bottom: ${({ theme }) => theme.spacing.md};
  position: relative;
  padding-left: ${({ theme }) => theme.spacing.md};
  border-left: 3px solid ${({ theme }) => theme.text.accent};
`;

const TestimonialAuthor = styled.div`
  margin-top: ${({ theme }) => theme.spacing.md};
`;

const AuthorName = styled.h3`
  font-size: ${({ theme }) => theme.typography.fontSizes.lg};
  color: ${({ theme }) => theme.text.primary};
  margin-bottom: ${({ theme }) => theme.spacing.xxs};
`;

const AuthorPosition = styled.div`
  font-size: ${({ theme }) => theme.typography.fontSizes.sm};
  color: ${({ theme }) => theme.text.secondary};
`;

// Grid of testimonials
const TestimonialsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${({ theme }) => theme.spacing.lg};
  
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (min-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const TestimonialCard = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.lg};
  height: 100%;
`;

const TestimonialCardHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.md};
`;

const SmallTestimonialImage = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: ${({ theme }) => theme.spacing.md};
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const TestimonialCardContent = styled.div`
  flex: 1;
`;

const TestimonialCardQuote = styled.p`
  font-style: italic;
  margin-bottom: ${({ theme }) => theme.spacing.md};
`;

const LoadingState = styled.div`
  text-align: center;
  padding: ${({ theme }) => theme.spacing.xl};
  color: ${({ theme }) => theme.text.secondary};
`;

// Demo data for testimonials
const dummyTestimonials: Testimonial[] = [
  {
    id: '1',
    name: 'Sarah Johnson',
    company: 'OceanHarvest Inc.',
    position: 'Supply Chain Director',
    content: 'Mandjet\'s ecosystem risk analysis has transformed how we approach our seafood supply chain planning. We\'ve been able to identify vulnerabilities months in advance and implement mitigation strategies that saved us over $1.2M in the last year alone.',
    imageUrl: 'https://i.imgur.com/7uVEGpW.jpg'
  },
  {
    id: '2',
    name: 'Michael Wong',
    company: 'Terroir Vineyards',
    position: 'Chief Sustainability Officer',
    content: 'As climate change continues to impact our wine grape production, Mandjet has been an invaluable partner in forecasting yields and planning accordingly. Their species-specific data sets are unmatched in the industry.',
    imageUrl: 'https://i.imgur.com/J8uQIJb.jpg'
  },
  {
    id: '3',
    name: 'Dr. Emily Chen',
    company: 'Global Supply Resilience Institute',
    position: 'Lead Researcher',
    content: 'In my 15 years of studying supply chain resilience, I\'ve never encountered a tool as comprehensive as Mandjet. Their approach to combining climate data with species-specific biology creates projections that are both accurate and actionable.',
    imageUrl: 'https://i.imgur.com/9fWNCzr.jpg'
  },
  {
    id: '4',
    name: 'Robert Martinez',
    company: 'Coastal Fisheries Alliance',
    position: 'Executive Director',
    content: 'Our member companies have collectively reduced forecast error by 28% since implementing Mandjet\'s risk analysis tools. The ability to anticipate ecosystem shifts has been a game-changer for sustainable fishing practices.',
    imageUrl: 'https://i.imgur.com/nsFfrcs.jpg'
  },
  {
    id: '5',
    name: 'Alexandra Petrov',
    company: 'EcoTrade Solutions',
    position: 'Head of Analytics',
    content: 'The financial impact projections from Mandjet have become an essential component of our quarterly planning. Their habitat suitability indices have proven remarkably accurate across diverse geographic regions.',
    imageUrl: 'https://i.imgur.com/WwULFzJ.jpg'
  },
  {
    id: '6',
    name: 'James Wilson',
    company: 'Timber Resources International',
    position: 'Operations Manager',
    content: 'Mandjet\'s risk analysis for our oak and pine supply chains provided insights that our internal teams simply couldn\'t generate. We\'ve integrated their forecasts into our 5-year planning with excellent results.',
    imageUrl: 'https://i.imgur.com/Q4H2zns.jpg'
  },
  {
    id: '7',
    name: 'Lisa Tanaka',
    company: 'BlueWave Seafood',
    position: 'Sustainability Manager',
    content: 'What impresses me most about Mandjet is how they translate complex ecological data into clear business impacts. Our procurement team now has a common language with our sustainability specialists thanks to their platform.',
    imageUrl: 'https://i.imgur.com/HnbB8h5.jpg'
  }
];

const Testimonials: React.FC = () => {
  return (
    <TestimonialsContainer>
      <HeaderSection>
        <Heading>Testimonials</Heading>
      </HeaderSection>
      <div style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '300px', 
        width: '100%' 
      }}>
        <strong style={{ fontSize: '24px' }}>Coming soon...</strong>
                  </div>
    </TestimonialsContainer>
  );
};

export default Testimonials;