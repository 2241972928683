import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FiLock, FiMail, FiAlertCircle, FiLinkedin } from 'react-icons/fi';
import { toast } from 'react-hot-toast';
import { signIn, createUser, checkUserApproval, registerApprovalRequest } from '../../firebase/auth';
import { useAuth } from '../../contexts/AuthContext';
import Button from '../../components/ui/Button';
import Card from '../../components/ui/Card';

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(135deg, ${({ theme }) => theme.colors.primary} 0%, ${({ theme }) => theme.colors.secondary} 100%);
  padding: ${({ theme }) => theme.spacing.lg};
`;

const LoginCard = styled(Card)`
  width: 100%;
  max-width: 420px;
  padding: ${({ theme }) => theme.spacing.xl};
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.95);
`;

const LoginHeader = styled.div`
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing.lg};
`;

const Title = styled.h1`
  font-size: ${({ theme }) => theme.typography.fontSizes.xxxl};
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: ${({ theme }) => theme.spacing.sm};
  font-weight: 300;
  letter-spacing: -0.5px;
`;

const Subtitle = styled.p`
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: ${({ theme }) => theme.typography.fontSizes.md};
  margin-bottom: ${({ theme }) => theme.spacing.md};
`;

const FormGroup = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.md};
`;

const Label = styled.label`
  display: block;
  margin-bottom: ${({ theme }) => theme.spacing.xs};
  color: ${({ theme }) => theme.colors.text};
  font-weight: ${({ theme }) => theme.typography.fontWeights.semiBold};
`;

const InputGroup = styled.div`
  position: relative;
`;

const Input = styled.input`
  width: 100%;
  padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.md}`};
  padding-left: 40px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  background-color: ${({ theme }) => theme.colors.surface};
  color: ${({ theme }) => theme.colors.text};
  transition: ${({ theme }) => theme.transitions.default};
  height: 48px;
  font-size: ${({ theme }) => theme.typography.fontSizes.md};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.03);
  
  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.primary};
    box-shadow: 0 0 0 3px rgba(23, 130, 106, 0.1);
  }
`;

const InputIcon = styled.div`
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: ${({ theme }) => theme.colors.textSecondary};
`;

const SwitchMode = styled.p`
  text-align: center;
  margin-top: ${({ theme }) => theme.spacing.md};
`;

const SwitchLink = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  font-weight: ${({ theme }) => theme.typography.fontWeights.semiBold};
  
  &:hover {
    text-decoration: underline;
  }
`;

const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xs};
  margin-top: ${({ theme }) => theme.spacing.xs};
  color: ${({ theme }) => theme.colors.error};
  font-size: ${({ theme }) => theme.typography.fontSizes.sm};
`;

const ContactInfo = styled.div`
  text-align: center;
  margin-top: ${({ theme }) => theme.spacing.lg};
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: ${({ theme }) => theme.typography.fontSizes.sm};
`;

const ContactLink = styled.a`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.typography.fontWeights.semiBold};
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
`;

const SuccessMessage = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xs};
  margin-top: ${({ theme }) => theme.spacing.sm};
  color: ${({ theme }) => theme.colors.success};
  font-size: ${({ theme }) => theme.typography.fontSizes.sm};
  padding: ${({ theme }) => theme.spacing.sm};
  background-color: rgba(58, 193, 140, 0.1);
  border-radius: ${({ theme }) => theme.borderRadius.default};
`;

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [linkedinUrl, setLinkedinUrl] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isRegistering, setIsRegistering] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showApprovalMessage, setShowApprovalMessage] = useState(false);
  
  const navigate = useNavigate();
  const { isAuthenticated, isApproved, requestApproval } = useAuth();
  
  useEffect(() => {
    // If user is already authenticated and approved, redirect to dashboard
    if (isAuthenticated && isApproved) {
      navigate('/');
    }
    // If user is authenticated but not approved, redirect to approval pending page
    else if (isAuthenticated && !isApproved) {
      navigate('/approval-pending');
    }
  }, [isAuthenticated, isApproved, navigate]);
  
  // LinkedIn URL validation
  const isValidLinkedinUrl = (url: string) => {
    const regex = /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[a-zA-Z0-9_-]+\/?$/;
    return regex.test(url);
  };
  
  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setShowApprovalMessage(false);
    
    if (!email || !password) {
      setError('Please enter both email and password');
      return;
    }
    
    // Normalize email
    const normalizedEmail = email.toLowerCase().trim();
    console.log('Normalized email for authentication:', normalizedEmail);
    
    if (isRegistering) {
      if (password !== confirmPassword) {
        setError('Passwords do not match');
        return;
      }
      
      if (!linkedinUrl) {
        setError('Please enter your LinkedIn profile URL');
        return;
      }
      
      if (!isValidLinkedinUrl(linkedinUrl)) {
        setError('Please enter a valid LinkedIn profile URL (e.g., https://linkedin.com/in/username)');
        return;
      }
    }
    
    if (password.length < 6) {
      setError('Password must be at least 6 characters long');
      return;
    }
    
    try {
      setLoading(true);
      
      if (isRegistering) {
        try {
          // Register new user
          const userCredential = await createUser(normalizedEmail, password);
          console.log('User created successfully:', userCredential.user.uid);
          
          // First, check if the user is pre-approved
          const isUserApproved = await checkUserApproval(normalizedEmail);
          console.log('Pre-approval check result:', isUserApproved);
          
          if (!isUserApproved) {
            console.log('User is not pre-approved, registering approval request');
            // If not approved, register approval request
            const requestResult = await requestApproval(normalizedEmail);
            
            if (requestResult) {
              console.log('Approval request registered successfully');
              setShowApprovalMessage(true);
              toast.success('Registration successful! Approval request has been submitted.');
              
              // Wait a moment before redirecting to the approval pending page
              setTimeout(() => {
                navigate('/approval-pending');
              }, 3000);
            } else {
              console.error('Failed to register approval request');
              setError('Your account was created, but we could not register your approval request. Please contact support.');
            }
          } else {
            console.log('User is pre-approved');
            toast.success('Account created successfully!');
            navigate('/');
          }
        } catch (registerError: any) {
          console.error('Registration error:', registerError);
          let errorMsg = 'Registration failed. Please try again.';
          
          // Handle Firebase registration errors
          if (registerError.code === 'auth/email-already-in-use') {
            errorMsg = 'This email is already in use. Please try signing in instead.';
          } else if (registerError.code === 'auth/invalid-email') {
            errorMsg = 'Please enter a valid email address.';
          } else if (registerError.code === 'auth/weak-password') {
            errorMsg = 'Password should be at least 6 characters.';
          } else if (registerError.code === 'auth/operation-not-allowed') {
            errorMsg = 'Email/password sign up is not enabled for this project. Please contact support.';
          } else if (registerError.message?.includes('400')) {
            errorMsg = 'Registration is currently unavailable. Please try again later or contact support.';
          }
          
          setError(errorMsg);
          toast.error(errorMsg);
          return;
        }
      } else {
        try {
          // Login existing user
          console.log('Attempting to sign in with email:', normalizedEmail);
          const userCredential = await signIn(normalizedEmail, password);
          console.log('User signed in:', userCredential.user.uid);
          
          // Wait a moment for Firebase auth state to fully initialize
          console.log('Waiting for auth state to initialize...');
          await new Promise(resolve => setTimeout(resolve, 500));
          
          // Directly check if the user is approved from Firestore
          console.log('Checking approval status directly from Firestore...');
          try {
            const isUserApproved = await checkUserApproval(normalizedEmail);
            console.log('User approval status after login:', isUserApproved);
            
            if (isUserApproved) {
              toast.success('Logged in successfully!');
              // Wait for state updates before navigating
              setTimeout(() => {
                navigate('/');
              }, 500);
            } else {
              // If not approved, display error message and redirect to pending page
              const approvalMsg = 'Your account is pending approval. Please contact chris@alpinesustains.com for expedited access.';
              setError(approvalMsg);
              toast.error(approvalMsg);
              setTimeout(() => {
                navigate('/approval-pending');
              }, 500);
            }
          } catch (approvalError) {
            console.error('Error checking approval status:', approvalError);
            const errorMsg = 'Could not verify your approval status. Please try again or contact support.';
            setError(errorMsg);
            toast.error(errorMsg);
          }
        } catch (loginError: any) {
          console.error('Login error:', loginError);
          let errorMsg = 'Login failed. Please try again.';
          
          // Handle Firebase login errors
          if (loginError.code === 'auth/user-not-found' || loginError.code === 'auth/wrong-password') {
            errorMsg = 'Invalid email or password.';
          } else if (loginError.code === 'auth/invalid-email') {
            errorMsg = 'Please enter a valid email address.';
          } else if (loginError.code === 'auth/user-disabled') {
            errorMsg = 'This account has been disabled. Please contact support.';
          } else if (loginError.code === 'auth/too-many-requests') {
            errorMsg = 'Too many unsuccessful login attempts. Please try again later.';
          } else if (loginError.message?.includes('400')) {
            errorMsg = 'Login is currently unavailable. Please try again later or contact support.';
          }
          
          setError(errorMsg);
          toast.error(errorMsg);
          return;
        }
      }
    } catch (err: any) {
      console.error('Authentication error:', err);
      let errorMessage = 'An unexpected error occurred. Please try again later.';
      setError(errorMessage);
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };
  
  const toggleMode = () => {
    setIsRegistering(!isRegistering);
    setError('');
    setPassword('');
    setConfirmPassword('');
    setLinkedinUrl('');
    setShowApprovalMessage(false);
  };
  
  return (
    <LoginContainer>
      <LoginCard>
        <LoginHeader>
          <Title>Mandjet</Title>
          <Subtitle>
            Forecasting your supply chain and operation risk to climate change
          </Subtitle>
        </LoginHeader>
        
        <form onSubmit={handleLogin}>
          <FormGroup>
            <Label htmlFor="email">Email</Label>
            <InputGroup>
              <InputIcon>
                <FiMail size={18} />
              </InputIcon>
              <Input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                required
              />
            </InputGroup>
          </FormGroup>
          
          {isRegistering && (
            <FormGroup>
              <Label htmlFor="linkedinUrl">LinkedIn Profile URL</Label>
              <InputGroup>
                <InputIcon>
                  <FiLinkedin size={18} />
                </InputIcon>
                <Input
                  id="linkedinUrl"
                  type="url"
                  value={linkedinUrl}
                  onChange={(e) => setLinkedinUrl(e.target.value)}
                  placeholder="https://linkedin.com/in/yourprofile"
                  required
                />
              </InputGroup>
            </FormGroup>
          )}
          
          <FormGroup>
            <Label htmlFor="password">Password</Label>
            <InputGroup>
              <InputIcon>
                <FiLock size={18} />
              </InputIcon>
              <Input
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter your password"
                required
              />
            </InputGroup>
          </FormGroup>
          
          {isRegistering && (
            <FormGroup>
              <Label htmlFor="confirmPassword">Confirm Password</Label>
              <InputGroup>
                <InputIcon>
                  <FiLock size={18} />
                </InputIcon>
                <Input
                  id="confirmPassword"
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Confirm your password"
                  required
                />
              </InputGroup>
            </FormGroup>
          )}
          
          {error && (
            <ErrorMessage>
              <FiAlertCircle size={16} />
              {error}
            </ErrorMessage>
          )}
          
          {showApprovalMessage && (
            <SuccessMessage>
              Your registration was successful! Your access request is pending approval.
              You will be redirected to the approval pending page.
            </SuccessMessage>
          )}
          
          <Button
            type="submit"
            variant="primary"
            fullWidth
            size="large"
            isLoading={loading}
          >
            {isRegistering ? 'Create Account' : 'Sign In'}
          </Button>
        </form>
        
        <SwitchMode>
          {isRegistering ? 'Already have an account?' : "Don't have an account?"}
          {' '}
          <SwitchLink
            type="button"
            onClick={toggleMode}
          >
            {isRegistering ? 'Sign In' : 'Register'}
          </SwitchLink>
        </SwitchMode>
        
        <ContactInfo>
          Need access? Contact <ContactLink href="mailto:chris@alpinesustains.com">chris@alpinesustains.com</ContactLink>
        </ContactInfo>
      </LoginCard>
    </LoginContainer>
  );
};

export default Login;