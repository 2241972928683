import React from 'react';
import styled, { css } from 'styled-components';

type CardVariant = 'default' | 'elevated' | 'outlined';
type CardSize = 'sm' | 'md' | 'lg';

interface CardProps {
  variant?: CardVariant;
  size?: CardSize;
  clickable?: boolean;
  fullWidth?: boolean;
  noPadding?: boolean;
  className?: string;
  onClick?: () => void;
  children: React.ReactNode;
}

const CardContainer = styled.div<CardProps>`
  background-color: ${({ theme }) => theme.background.card};
  border-radius: ${({ theme }) => theme.borders.radius.md};
  overflow: hidden;
  transition: ${({ theme }) => theme.transitions.default};
  
  /* Size variants */
  ${({ size, noPadding, theme }) => {
    switch(size) {
      case 'sm':
        return css`
          padding: ${noPadding ? '0' : theme.spacing.sm};
        `;
      case 'lg':
        return css`
          padding: ${noPadding ? '0' : theme.spacing.lg};
        `;
      case 'md':
      default:
        return css`
          padding: ${noPadding ? '0' : theme.spacing.md};
        `;
    }
  }}
  
  /* Style variants */
  ${({ variant, theme }) => {
    switch(variant) {
      case 'elevated':
        return css`
          box-shadow: ${theme.shadow};
          border: none;
        `;
      case 'outlined':
        return css`
          border: 1px solid ${theme.border};
          box-shadow: none;
        `;
      case 'default':
      default:
        return css`
          border: none;
          box-shadow: ${theme.shadow};
        `;
    }
  }}
  
  /* Clickable behavior */
  ${({ clickable, theme }) => clickable && css`
    cursor: pointer;
    
    &:hover {
      transform: translateY(-2px);
      box-shadow: ${theme.hoverShadow};
    }
    
    &:active {
      transform: translateY(0);
    }
  `}
  
  /* Full width option */
  width: ${({ fullWidth }) => fullWidth ? '100%' : 'auto'};
`;

const Card: React.FC<CardProps> = ({
  variant = 'default',
  size = 'md',
  clickable = false,
  fullWidth = false,
  noPadding = false,
  className,
  onClick,
  children
}) => {
  return (
    <CardContainer
      variant={variant}
      size={size}
      clickable={clickable}
      fullWidth={fullWidth}
      noPadding={noPadding}
      className={className}
      onClick={clickable ? onClick : undefined}
    >
      {children}
    </CardContainer>
  );
};

export default Card;