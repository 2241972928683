import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { ThemeProvider } from './contexts/ThemeContext';
import { AuthProvider } from './contexts/AuthContext';
import GlobalStyle from './theme/globalStyles';
import PrivateRoute from './components/layout/PrivateRoute';

// Pages
import Login from './pages/auth/Login';
import ApprovalPending from './pages/auth/ApprovalPending';
import Dashboard from './pages/dashboard';
import PitchDeck from './pages/pitchDeck';
import Testimonials from './pages/testimonials';
import Walkthrough from './pages/walkthrough';
import Resources from './pages/resources';
import RiskAnalysis from './pages/riskAnalysis';

const App: React.FC = () => {
  return (
    <ThemeProvider>
      <AuthProvider>
        <GlobalStyle />
        <Router>
          <Routes>
            {/* Public routes */}
            <Route path="/login" element={<Login />} />
            <Route path="/approval-pending" element={<ApprovalPending />} />
            
            {/* Protected routes (authentication required) */}
            <Route element={<PrivateRoute />}>
              <Route path="/" element={<Dashboard />} />
              <Route path="/pitch-deck" element={<PitchDeck />} />
              <Route path="/testimonials" element={<Testimonials />} />
              <Route path="/walkthrough" element={<Walkthrough />} />
              <Route path="/resources" element={<Resources />} />
              <Route path="/risk-analysis" element={<RiskAnalysis />} />
            </Route>
            
            {/* Fallback route */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Router>
        
        <Toaster
          position="top-right"
          toastOptions={{
            duration: 5000,
            style: {
              borderRadius: '6px',
              background: '#333',
              color: '#fff',
            }
          }}
        />
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;