import { createGlobalStyle, DefaultTheme } from 'styled-components';

// Extend the DefaultTheme interface with our custom theme properties
declare module 'styled-components' {
  export interface DefaultTheme {
    mode: string;
    background: {
      primary: string;
      secondary: string;
      tertiary: string;
      card: string;
    };
    text: {
      primary: string;
      secondary: string;
      accent: string;
    };
    button: {
      primary: {
        background: string;
        text: string;
        hover: string;
      };
      secondary: {
        background: string;
        text: string;
        hover: string;
      };
      tertiary: {
        background: string;
        text: string;
        hover: string;
      };
    };
    border: string;
    shadow: string;
    hoverShadow: string;
    colors: Record<string, string>;
    typography: {
      fontFamily: string;
      fontWeights: {
        light: number;
        regular: number;
        semiBold: number;
      };
      fontSizes: {
        xs: string;
        sm: string;
        md: string;
        lg: string;
        xl: string;
        xxl: string;
        xxxl: string;
        heading: string;
      };
    };
    spacing: {
      xxs: string;
      xs: string;
      sm: string;
      md: string;
      lg: string;
      xl: string;
      xxl: string;
      xxxl: string;
    };
    borders: {
      radius: {
        sm: string;
        md: string;
        lg: string;
        circle: string;
      };
      width: {
        thin: string;
        medium: string;
        thick: string;
      };
    };
    transitions: {
      default: string;
      quick: string;
      slow: string;
    };
  }
}

const GlobalStyle = createGlobalStyle`
  /* Import fonts */
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600&display=swap');
  
  /* Reset */
  *, *::before, *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  /* Root variables */
  :root {
    --deep-navy: #0E1A26;
    --emerald-green: #17826A;
    --ocean-blue: #29587D;
    --sand-beige: #F0E9DA;
    --light-gray: #F6F7F8;
    --dark-background: #0A0D0F;
    --light-text: #E2E2E2;
    --dark-text: #2D2D2D;
    
    --transition-default: 0.3s ease-in-out;
    --border-radius: 6px;
  }
  
  /* Base styles */
  html, body {
    height: 100%;
    font-family: 'Inter', 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  body {
    background-color: ${({ theme }) => theme.background.primary};
    color: ${({ theme }) => theme.text.primary};
    transition: background-color var(--transition-default), 
                color var(--transition-default);
  }
  
  #root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  /* Typography */
  h1, h2, h3, h4, h5, h6 {
    font-weight: 300; /* Light weight for headers */
    margin-bottom: 1rem;
    color: ${({ theme }) => theme.text.secondary};
    line-height: 1.3;
  }
  
  h1 {
    font-size: 2.5rem;
  }
  
  h2 {
    font-size: 2rem;
  }
  
  h3 {
    font-size: 1.5rem;
  }
  
  h4 {
    font-size: 1.25rem;
  }
  
  h5 {
    font-size: 1.125rem;
  }
  
  h6 {
    font-size: 1rem;
  }
  
  p {
    margin-bottom: 1rem;
    line-height: 1.6;
  }
  
  a {
    color: ${({ theme }) => theme.text.accent};
    text-decoration: none;
    transition: color var(--transition-default);
    
    &:hover {
      text-decoration: underline;
    }
  }
  
  /* Button Reset */
  button {
    background: none;
    border: none;
    cursor: pointer;
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    transition: all var(--transition-default);
    
    &:disabled {
      cursor: not-allowed;
      opacity: 0.6;
    }
  }
  
  /* Form elements */
  input, textarea, select {
    font-family: inherit;
    font-size: inherit;
    padding: 0.75rem 1rem;
    border-radius: var(--border-radius);
    border: 1px solid ${({ theme }) => theme.border};
    background-color: ${({ theme }) => theme.background.primary};
    color: ${({ theme }) => theme.text.primary};
    transition: border-color var(--transition-default);
    
    &:focus {
      outline: none;
      border-color: ${({ theme }) => theme.text.accent};
    }
  }
  
  /* Utility classes */
  .container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
  }
  
  .text-center {
    text-align: center;
  }
  
  .text-accent {
    color: ${({ theme }) => theme.text.accent};
  }
  
  .mt-1 { margin-top: 0.5rem; }
  .mt-2 { margin-top: 1rem; }
  .mt-3 { margin-top: 1.5rem; }
  .mt-4 { margin-top: 2rem; }
  .mt-5 { margin-top: 3rem; }
  
  .mb-1 { margin-bottom: 0.5rem; }
  .mb-2 { margin-bottom: 1rem; }
  .mb-3 { margin-bottom: 1.5rem; }
  .mb-4 { margin-bottom: 2rem; }
  .mb-5 { margin-bottom: 3rem; }
  
  /* Animations */
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  @keyframes slideUp {
    from { transform: translateY(20px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
  }
  
  .fade-in {
    animation: fadeIn var(--transition-default);
  }
  
  .slide-up {
    animation: slideUp var(--transition-default);
  }
`;

export default GlobalStyle;