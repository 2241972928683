import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { 
  FiSearch, 
  FiDownload, 
  FiFile, 
  FiFileText, 
  FiImage, 
  FiGrid, 
  FiList 
} from 'react-icons/fi';
import { getResources } from '../../firebase/firestore';
import { Resource, ResourceCategory } from '../../types';
import Card from '../../components/ui/Card';
import Button from '../../components/ui/Button';

const ResourcesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.lg};
`;

const HeaderSection = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.lg};
`;

const Heading = styled.h1`
  font-size: ${({ theme }) => theme.typography.fontSizes.heading};
  color: ${({ theme }) => theme.text.primary};
  margin-bottom: ${({ theme }) => theme.spacing.md};
`;

const Subheading = styled.p`
  color: ${({ theme }) => theme.text.secondary};
`;

const ControlsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.md};
  margin-bottom: ${({ theme }) => theme.spacing.lg};
  
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const SearchAndFilter = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.md};
  flex: 1;
  
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const SearchContainer = styled.div`
  position: relative;
  flex: 1;
  min-width: 250px;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.md}`};
  padding-left: 40px;
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: ${({ theme }) => theme.borders.radius.md};
  background-color: ${({ theme }) => theme.background.primary};
  color: ${({ theme }) => theme.text.primary};
  
  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.text.accent};
  }
`;

const SearchIcon = styled.div`
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: ${({ theme }) => theme.text.secondary};
`;

const FilterGroup = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.xs};
  flex-wrap: wrap;
`;

const FilterButton = styled(Button)<{ active: boolean }>`
  background-color: ${({ active, theme }) => 
    active ? theme.button.primary.background : theme.background.secondary};
  color: ${({ active, theme }) => 
    active ? theme.button.primary.text : theme.text.primary};
  
  &:hover {
    background-color: ${({ active, theme }) => 
      active ? theme.button.primary.hover : theme.button.tertiary.hover};
  }
`;

const ViewOptions = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.xs};
`;

// Grid view
const ResourcesGrid = styled.div<{ viewMode: 'grid' | 'list' }>`
  display: grid;
  grid-template-columns: ${({ viewMode }) => 
    viewMode === 'grid' ? 'repeat(auto-fill, minmax(300px, 1fr))' : '1fr'};
  gap: ${({ theme }) => theme.spacing.md};
`;

// Resource card components
const ResourceCard = styled(Card)<{ viewMode: 'grid' | 'list' }>`
  display: flex;
  flex-direction: ${({ viewMode }) => viewMode === 'grid' ? 'column' : 'row'};
  padding: 0;
  overflow: hidden;
  height: 100%;
`;

const ResourceIconContainer = styled.div<{ fileType: string, viewMode: 'grid' | 'list' }>`
  background-color: ${({ fileType, theme }) => {
    switch(fileType) {
      case 'pdf':
        return theme.colors.error;
      case 'xlsx':
      case 'csv':
        return theme.colors.success;
      case 'pptx':
        return theme.colors.warning;
      default:
        return theme.colors.oceanBlue;
    }
  }};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  
  ${({ viewMode }) => viewMode === 'grid' ? `
    height: 120px;
    width: 100%;
  ` : `
    min-width: 80px;
    height: 100%;
  `}
`;

const ResourceInfo = styled.div`
  padding: ${({ theme }) => theme.spacing.md};
  flex: 1;
`;

const ResourceTitle = styled.h3`
  font-size: ${({ theme }) => theme.typography.fontSizes.lg};
  margin-bottom: ${({ theme }) => theme.spacing.xs};
`;

const ResourceDescription = styled.p`
  color: ${({ theme }) => theme.text.secondary};
  font-size: ${({ theme }) => theme.typography.fontSizes.sm};
  margin-bottom: ${({ theme }) => theme.spacing.md};
`;

const ResourceMeta = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
`;

const ResourceDate = styled.span`
  color: ${({ theme }) => theme.text.secondary};
  font-size: ${({ theme }) => theme.typography.fontSizes.sm};
`;

const CategoryBadge = styled.span`
  background-color: ${({ theme }) => theme.background.secondary};
  color: ${({ theme }) => theme.text.primary};
  padding: ${({ theme }) => `${theme.spacing.xxs} ${theme.spacing.xs}`};
  border-radius: ${({ theme }) => theme.borders.radius.sm};
  font-size: ${({ theme }) => theme.typography.fontSizes.xs};
  text-transform: capitalize;
`;

const EmptyState = styled.div`
  text-align: center;
  padding: ${({ theme }) => theme.spacing.xl};
  background-color: ${({ theme }) => theme.background.secondary};
  border-radius: ${({ theme }) => theme.borders.radius.md};
`;

const LoadingState = styled.div`
  text-align: center;
  padding: ${({ theme }) => theme.spacing.xl};
  color: ${({ theme }) => theme.text.secondary};
`;

// Demo data for resources
const dummyResources: Resource[] = [
  {
    id: '1',
    title: 'Marine Ecosystem Risk Analysis Methodology',
    description: 'A comprehensive overview of our approach to analyzing and forecasting risks in marine ecosystems and their impact on supply chains.',
    fileUrl: '/files/marine-risk-analysis.pdf',
    fileType: 'pdf',
    dateAdded: new Date(2023, 10, 15),
    category: 'whitepaper'
  },
  {
    id: '2',
    title: 'Quarterly Market Impact Report - Q4 2023',
    description: 'Analysis of ecosystem changes and their projected financial impact on major seafood supply chains for the latest quarter.',
    fileUrl: '/files/q4-2023-market-report.pdf',
    fileType: 'pdf',
    dateAdded: new Date(2023, 11, 5),
    category: 'report'
  },
  {
    id: '3',
    title: 'Climate Adaptation Strategies for Supply Chain Resilience',
    description: 'Strategic frameworks and actionable insights for adapting supply chains to ecosystem disruptions.',
    fileUrl: '/files/adaptation-strategies.pdf',
    fileType: 'pdf',
    dateAdded: new Date(2023, 9, 28),
    category: 'whitepaper'
  },
  {
    id: '4',
    title: 'Habitat Suitability Data - Southeast Asian Fisheries',
    description: 'Raw data exports of habitat suitability indices for key commercial species in Southeast Asian waters.',
    fileUrl: '/files/sea-fisheries-data.xlsx',
    fileType: 'xlsx',
    dateAdded: new Date(2023, 11, 10),
    category: 'data'
  },
  {
    id: '5',
    title: 'Investor Presentation - Mandjet Growth Trajectory',
    description: 'Overview of Mandjet market position, growth strategy, and financial projections for investors.',
    fileUrl: '/files/investor-presentation.pptx',
    fileType: 'pptx',
    dateAdded: new Date(2023, 10, 20),
    category: 'presentation'
  },
  {
    id: '6',
    title: 'Case Study: Yellowfin Tuna Supply Chain Optimization',
    description: 'How a major seafood processor used Mandjet to reduce procurement costs by 18% through better risk forecasting.',
    fileUrl: '/files/yellowfin-case-study.pdf',
    fileType: 'pdf',
    dateAdded: new Date(2023, 8, 17),
    category: 'report'
  },
  {
    id: '7',
    title: 'Terrestrial Crop Yield Forecasting Model',
    description: 'Technical paper on our methodology for predicting climate impacts on agricultural yield for key commodities.',
    fileUrl: '/files/crop-yield-model.pdf',
    fileType: 'pdf',
    dateAdded: new Date(2023, 7, 25),
    category: 'whitepaper'
  },
  {
    id: '8',
    title: 'Global Wine Regions Climate Risk Assessment',
    description: 'Comprehensive analysis of climate change impacts on major wine-producing regions and projected grape yield changes.',
    fileUrl: '/files/wine-climate-risk.pdf',
    fileType: 'pdf',
    dateAdded: new Date(2023, 10, 5),
    category: 'report'
  }
];

const Resources: React.FC = () => {
  const [resources, setResources] = useState<Resource[]>([]);
  const [filteredResources, setFilteredResources] = useState<Resource[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<ResourceCategory | 'all'>('all');
  const [viewMode, setViewMode] = useState<'grid' | 'list'>('grid');
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    const fetchResources = async () => {
      try {
        // In a real app, you would fetch from Firebase
        // const resourcesData = await getResources();
        
        // Using dummy data for demo
        // Convert the dates to proper Date objects
        const processedResources = dummyResources.map(resource => ({
          ...resource,
          dateAdded: resource.dateAdded instanceof Date 
            ? resource.dateAdded 
            : new Date(resource.dateAdded)
        }));
        
        setResources(processedResources);
        setFilteredResources(processedResources);
      } catch (error) {
        console.error('Error fetching resources:', error);
      } finally {
        setLoading(false);
      }
    };
    
    fetchResources();
  }, []);
  
  // Filter resources based on search term and category
  useEffect(() => {
    const filtered = resources.filter(resource => {
      const matchesSearch = 
        resource.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        resource.description.toLowerCase().includes(searchTerm.toLowerCase());
        
      const matchesCategory = selectedCategory === 'all' || resource.category === selectedCategory;
      
      return matchesSearch && matchesCategory;
    });
    
    setFilteredResources(filtered);
  }, [searchTerm, selectedCategory, resources]);
  
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };
  
  const handleCategoryFilter = (category: ResourceCategory | 'all') => {
    setSelectedCategory(category);
  };
  
  const handleDownload = (resource: Resource) => {
    // In a real app, this would trigger the actual download
    alert(`In a production environment, this would download: ${resource.title}`);
  };
  
  const formatDate = (date: Date) => {
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    }).format(date);
  };
  
  const getFileIcon = (fileType: string) => {
    switch(fileType) {
      case 'pdf':
        return <FiFileText size={24} />;
      case 'xlsx':
      case 'csv':
        return <FiGrid size={24} />;
      case 'pptx':
        return <FiImage size={24} />;
      default:
        return <FiFile size={24} />;
    }
  };
  
  if (loading) {
    return <LoadingState>Loading resources...</LoadingState>;
  }
  
  return (
    <ResourcesContainer>
      <HeaderSection>
        <Heading>Investor Resources</Heading>
        <Subheading>
          Access reports, presentations, and data related to our ecosystem risk analysis platform.
        </Subheading>
      </HeaderSection>
      
      <ControlsRow>
        <SearchAndFilter>
          <SearchContainer>
            <SearchIcon>
              <FiSearch />
            </SearchIcon>
            <SearchInput
              type="text"
              placeholder="Search resources..."
              value={searchTerm}
              onChange={handleSearch}
            />
          </SearchContainer>
          
          <FilterGroup>
            <FilterButton
              variant="tertiary"
              size="sm"
              active={selectedCategory === 'all'}
              onClick={() => handleCategoryFilter('all')}
            >
              All
            </FilterButton>
            <FilterButton
              variant="tertiary"
              size="sm"
              active={selectedCategory === 'report'}
              onClick={() => handleCategoryFilter('report')}
            >
              Reports
            </FilterButton>
            <FilterButton
              variant="tertiary"
              size="sm"
              active={selectedCategory === 'whitepaper'}
              onClick={() => handleCategoryFilter('whitepaper')}
            >
              Whitepapers
            </FilterButton>
            <FilterButton
              variant="tertiary"
              size="sm"
              active={selectedCategory === 'presentation'}
              onClick={() => handleCategoryFilter('presentation')}
            >
              Presentations
            </FilterButton>
            <FilterButton
              variant="tertiary"
              size="sm"
              active={selectedCategory === 'data'}
              onClick={() => handleCategoryFilter('data')}
            >
              Data
            </FilterButton>
          </FilterGroup>
        </SearchAndFilter>
        
        <ViewOptions>
          <Button
            variant={viewMode === 'grid' ? 'primary' : 'tertiary'}
            size="sm"
            leftIcon={<FiGrid />}
            onClick={() => setViewMode('grid')}
          >
            Grid
          </Button>
          <Button
            variant={viewMode === 'list' ? 'primary' : 'tertiary'}
            size="sm"
            leftIcon={<FiList />}
            onClick={() => setViewMode('list')}
          >
            List
          </Button>
        </ViewOptions>
      </ControlsRow>
      
      {filteredResources.length > 0 ? (
        <ResourcesGrid viewMode={viewMode}>
          {filteredResources.map((resource) => (
            <ResourceCard key={resource.id} viewMode={viewMode}>
              <ResourceIconContainer fileType={resource.fileType} viewMode={viewMode}>
                {getFileIcon(resource.fileType)}
              </ResourceIconContainer>
              
              <ResourceInfo>
                <ResourceTitle>{resource.title}</ResourceTitle>
                <ResourceDescription>{resource.description}</ResourceDescription>
                
                <ResourceMeta>
                  <div>
                    <ResourceDate>{formatDate(resource.dateAdded)}</ResourceDate>
                    <div style={{ marginTop: '4px' }}>
                      <CategoryBadge>{resource.category}</CategoryBadge>
                    </div>
                  </div>
                  
                  <Button
                    variant="tertiary"
                    size="sm"
                    leftIcon={<FiDownload />}
                    onClick={() => handleDownload(resource)}
                  >
                    Download
                  </Button>
                </ResourceMeta>
              </ResourceInfo>
            </ResourceCard>
          ))}
        </ResourcesGrid>
      ) : (
        <EmptyState>
          <p>No resources found matching your search criteria.</p>
        </EmptyState>
      )}
    </ResourcesContainer>
  );
};

export default Resources;