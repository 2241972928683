import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { FiMenu, FiX, FiLogOut } from 'react-icons/fi';
import { useAuth } from '../../contexts/AuthContext';
import { signOut } from '../../firebase/auth';
import Button from '../ui/Button';

const NavbarContainer = styled.nav`
  background-color: ${({ theme }) => theme.background.primary};
  padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.md}`};
  box-shadow: ${({ theme }) => theme.shadow};
  position: sticky;
  top: 0;
  z-index: 1000;
`;

const NavContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  padding: 0 ${({ theme }) => theme.spacing.md};
`;

const Logo = styled(Link)`
  font-size: ${({ theme }) => theme.typography.fontSizes.xl};
  font-weight: ${({ theme }) => theme.typography.fontWeights.semiBold};
  color: ${({ theme }) => theme.text.accent};
  text-decoration: none;
  display: flex;
  align-items: center;
  
  &:hover {
    text-decoration: none;
  }
`;

const NavLinks = styled.div<{ isOpen: boolean }>`
  display: flex;
  gap: ${({ theme }) => theme.spacing.md};
  
  @media (max-width: 768px) {
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    background-color: ${({ theme }) => theme.background.primary};
    flex-direction: column;
    padding: ${({ theme }) => theme.spacing.lg};
    transform: ${({ isOpen }) => isOpen ? 'translateY(0)' : 'translateY(-100%)'};
    opacity: ${({ isOpen }) => isOpen ? 1 : 0};
    visibility: ${({ isOpen }) => isOpen ? 'visible' : 'hidden'};
    transition: all ${({ theme }) => theme.transitions.default};
    box-shadow: ${({ theme }) => theme.shadow};
  }
`;

const NavLink = styled(Link)<{ active?: boolean }>`
  color: ${({ theme, active }) => active ? theme.text.accent : theme.text.primary};
  text-decoration: none;
  padding: ${({ theme }) => `${theme.spacing.xs} ${theme.spacing.sm}`};
  border-radius: ${({ theme }) => theme.borders.radius.md};
  transition: ${({ theme }) => theme.transitions.quick};
  font-weight: ${({ active, theme }) => active ? theme.typography.fontWeights.semiBold : theme.typography.fontWeights.regular};
  
  &:hover {
    background-color: ${({ theme }) => theme.background.secondary};
    text-decoration: none;
  }
`;

const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
`;

const MenuButton = styled.button`
  display: none;
  background: none;
  border: none;
  color: ${({ theme }) => theme.text.primary};
  font-size: ${({ theme }) => theme.typography.fontSizes.lg};
  cursor: pointer;
  
  @media (max-width: 768px) {
    display: block;
  }
`;

const Navbar: React.FC = () => {
  const { isAuthenticated, currentUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  
  const handleLogout = async () => {
    try {
      await signOut();
      navigate('/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };
  
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  
  const isActive = (path: string) => {
    return location.pathname === path;
  };

  return (
    <NavbarContainer>
      <NavContent>
        <Logo to="/">Mandjet</Logo>
        
        <MenuButton onClick={toggleMenu}>
          {isMenuOpen ? <FiX /> : <FiMenu />}
        </MenuButton>
        
        <NavLinks isOpen={isMenuOpen}>
          <NavLink to="/" active={isActive('/')}>
            Dashboard
          </NavLink>
          <NavLink to="/pitch-deck" active={isActive('/pitch-deck')}>
            Pitch Deck
          </NavLink>
          <NavLink to="/testimonials" active={isActive('/testimonials')}>
            Testimonials
          </NavLink>
          <NavLink to="/walkthrough" active={isActive('/walkthrough')}>
            Walkthrough
          </NavLink>
        </NavLinks>
        
        <ActionButtons>
          {/* Action buttons can be added here if needed */}
        </ActionButtons>
      </NavContent>
    </NavbarContainer>
  );
};

export default Navbar;