import React from 'react';
import styled, { css } from 'styled-components';

type ButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'outline';
type ButtonSize = 'sm' | 'md' | 'lg' | 'large';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant;
  size?: ButtonSize;
  fullWidth?: boolean;
  isLoading?: boolean;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
}

// Base button styling
const ButtonBase = styled.button<Omit<ButtonProps, 'leftIcon' | 'rightIcon'>>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.borders.radius.md};
  font-weight: ${({ theme }) => theme.typography.fontWeights.semiBold};
  transition: ${({ theme }) => theme.transitions.default};
  white-space: nowrap;
  
  /* Size variants */
  ${({ size }) => {
    switch(size) {
      case 'sm':
        return css`
          font-size: ${({ theme }) => theme.typography.fontSizes.sm};
          padding: ${({ theme }) => `${theme.spacing.xxs} ${theme.spacing.sm}`};
        `;
      case 'lg':
      case 'large':
        return css`
          font-size: ${({ theme }) => theme.typography.fontSizes.lg};
          padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.lg}`};
        `;
      case 'md':
      default:
        return css`
          font-size: ${({ theme }) => theme.typography.fontSizes.md};
          padding: ${({ theme }) => `${theme.spacing.xs} ${theme.spacing.md}`};
        `;
    }
  }}
  
  /* Color variants */
  ${({ theme, variant }) => {
    switch(variant) {
      case 'secondary':
        return css`
          background-color: ${theme.button.secondary.background};
          color: ${theme.button.secondary.text};
          
          &:hover:not(:disabled) {
            background-color: ${theme.button.secondary.hover};
            box-shadow: ${theme.hoverShadow};
          }
        `;
      case 'tertiary':
        return css`
          background-color: ${theme.button.tertiary.background};
          color: ${theme.button.tertiary.text};
          border: 1px solid ${theme.border};
          
          &:hover:not(:disabled) {
            background-color: ${theme.button.tertiary.hover};
          }
        `;
      case 'outline':
        return css`
          background-color: transparent;
          color: ${theme.button.primary.background};
          border: 1px solid ${theme.button.primary.background};
          
          &:hover:not(:disabled) {
            background-color: rgba(23, 130, 106, 0.05);
          }
        `;
      case 'primary':
      default:
        return css`
          background-color: ${theme.button.primary.background};
          color: ${theme.button.primary.text};
          
          &:hover:not(:disabled) {
            background-color: ${theme.button.primary.hover};
            box-shadow: ${theme.hoverShadow};
          }
        `;
    }
  }}
  
  /* Full width option */
  width: ${({ fullWidth }) => fullWidth ? '100%' : 'auto'};
  
  /* Disabled state */
  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
  
  /* Loading state */
  ${({ isLoading }) => isLoading && css`
    position: relative;
    
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 1em;
      height: 1em;
      margin: -0.5em 0 0 -0.5em;
      border: 2px solid currentColor;
      border-top-color: transparent;
      border-radius: 50%;
      animation: spin 0.8s linear infinite;
    }
    
    @keyframes spin {
      to { transform: rotate(360deg); }
    }
    
    & > * {
      visibility: hidden;
    }
  `}
`;

// Button component with icon support
const Button: React.FC<ButtonProps> = ({
  children,
  variant = 'primary',
  size = 'md',
  fullWidth = false,
  isLoading = false,
  leftIcon,
  rightIcon,
  ...rest
}) => {
  // Normalize size (treat 'large' as 'lg')
  const normalizedSize = size === 'large' ? 'lg' : size;
  
  return (
    <ButtonBase 
      variant={variant} 
      size={normalizedSize} 
      fullWidth={fullWidth} 
      isLoading={isLoading} 
      disabled={isLoading || rest.disabled}
      {...rest}
    >
      {leftIcon && <span style={{ marginRight: '0.5rem' }}>{leftIcon}</span>}
      {children}
      {rightIcon && <span style={{ marginLeft: '0.5rem' }}>{rightIcon}</span>}
    </ButtonBase>
  );
};

export default Button;