import React, { createContext, useContext } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { lightTheme } from '../theme/themes';

interface ThemeContextProps {
  theme: 'light';
}

const ThemeContext = createContext<ThemeContextProps | undefined>(undefined);

export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // Always use light theme
  const theme: 'light' = 'light';
  
  // Effect to apply light mode
  document.body.classList.add('light-mode');
  document.body.classList.remove('dark-mode');
  
  // Always use light theme object
  const themeObject = lightTheme;

  return (
    <ThemeContext.Provider value={{ theme }}>
      <StyledThemeProvider theme={themeObject}>
        {children}
      </StyledThemeProvider>
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};