// Theme definitions following the branding requirements
const colors = {
  // Primary colors
  deepNavy: '#0E1A26',
  emeraldGreen: '#17826A',
  
  // Secondary colors
  oceanBlue: '#29587D',
  sandBeige: '#F0E9DA',
  
  // Neutral colors
  lightGray: '#F6F7F8',
  darkBackground: '#0A0D0F',
  lightText: '#E2E2E2',
  darkText: '#2D2D2D',
  
  // Additional colors
  white: '#FFFFFF',
  error: '#D83A34',
  success: '#3AC18C',
  warning: '#F5CB5C'
};

// Typography
const typography = {
  fontFamily: '"Inter", "Roboto", sans-serif',
  fontWeights: {
    light: 300,
    regular: 400,
    semiBold: 600,
    semibold: 600, // Alias for semiBold for compatibility
    bold: 700
  },
  fontSizes: {
    xs: '0.75rem',    // 12px
    sm: '0.875rem',   // 14px
    md: '1rem',       // 16px
    lg: '1.125rem',   // 18px
    xl: '1.25rem',    // 20px
    xxl: '1.5rem',    // 24px
    xxxl: '2rem',     // 32px
    heading: '2.5rem' // 40px
  },
  // Alias for fontSizes for compatibility with component usage
  get fontSize() {
    return {
      xs: this.fontSizes.xs,
      sm: this.fontSizes.sm,
      md: this.fontSizes.md,
      lg: this.fontSizes.lg,
      xl: this.fontSizes.xl,
      '2xl': this.fontSizes.xxl,
      '3xl': '1.875rem', // 30px
      '4xl': this.fontSizes.xxxl
    };
  }
};

// Spacing
const spacing = {
  xxs: '0.25rem',  // 4px
  xs: '0.5rem',    // 8px
  sm: '0.75rem',   // 12px
  md: '1rem',      // 16px
  lg: '1.5rem',    // 24px
  xl: '2rem',      // 32px
  xxl: '3rem',     // 48px
  xxxl: '4rem',    // 64px
  '2xl': '3rem'    // Alias for xxl
};

// Borders
const borders = {
  radius: {
    sm: '3px',
    md: '6px',
    lg: '12px',
    circle: '50%'
  },
  width: {
    thin: '1px',
    medium: '2px',
    thick: '4px'
  }
};

// BorderRadius - alias for compatibility with component usage
const borderRadius = {
  small: borders.radius.sm,
  default: borders.radius.md,
  large: borders.radius.lg
};

// Shadows
const shadowsBase = {
  sm: '0 1px 3px rgba(0, 0, 0, 0.06)',
  md: '0 2px 6px rgba(0, 0, 0, 0.08)',
  lg: '0 4px 12px rgba(0, 0, 0, 0.12)',
  hoverLight: '0 4px 16px rgba(0, 0, 0, 0.12)',
  hoverDark: '0 4px 16px rgba(0, 0, 0, 0.25)'
};

// Extended shadows with aliases
const shadows = {
  ...shadowsBase,
  default: shadowsBase.md,
  hover: shadowsBase.hoverLight
};

// Transitions
const transitions = {
  default: '0.3s ease-in-out',
  quick: '0.2s ease-in-out',
  slow: '0.5s ease-in-out'
};

// Base theme with shared properties
const baseTheme = {
  colors,
  typography,
  spacing,
  borders,
  borderRadius, // Add borderRadius for components
  shadows,      // Add shadows for components
  transitions
};

// Light theme
export const lightTheme = {
  ...baseTheme,
  mode: 'light',
  background: {
    primary: colors.white,
    secondary: colors.lightGray,
    tertiary: colors.sandBeige,
    card: colors.white
  },
  text: {
    primary: colors.darkText,
    secondary: colors.deepNavy,
    accent: colors.emeraldGreen
  },
  button: {
    primary: {
      background: colors.emeraldGreen,
      text: colors.white,
      hover: '#14725D' // Slightly darker emerald
    },
    secondary: {
      background: colors.oceanBlue,
      text: colors.white,
      hover: '#224D6B' // Slightly darker ocean blue
    },
    tertiary: {
      background: 'transparent',
      text: colors.deepNavy,
      hover: colors.lightGray
    }
  },
  border: colors.lightGray,
  shadow: shadows.md,
  hoverShadow: shadows.hoverLight,
  // Maps for component usage
  colors: {
    primary: colors.emeraldGreen,
    secondary: colors.oceanBlue,
    text: colors.darkText,
    textSecondary: '#666666',
    background: colors.white,
    surface: colors.lightGray,
    border: '#e0e0e0',
    ...colors
  }
};

// Dark theme
export const darkTheme = {
  ...baseTheme,
  mode: 'dark',
  background: {
    primary: colors.darkBackground,
    secondary: colors.deepNavy,
    tertiary: '#1A2C3A', // Slightly lighter than deepNavy
    card: '#161C21'      // Slightly lighter than darkBackground
  },
  text: {
    primary: colors.lightText,
    secondary: colors.sandBeige,
    accent: colors.emeraldGreen
  },
  button: {
    primary: {
      background: colors.emeraldGreen,
      text: colors.white,
      hover: '#1A9278' // Slightly lighter emerald for dark mode
    },
    secondary: {
      background: colors.oceanBlue,
      text: colors.white,
      hover: '#2F6590' // Slightly lighter ocean blue for dark mode
    },
    tertiary: {
      background: 'transparent',
      text: colors.lightText,
      hover: '#1D232A' // Very dark gray
    }
  },
  border: '#2A323A', // Dark mode border color
  shadow: '0 2px 6px rgba(0, 0, 0, 0.25)',
  hoverShadow: shadows.hoverDark,
  // Maps for component usage
  colors: {
    primary: colors.emeraldGreen,
    secondary: colors.oceanBlue,
    text: colors.lightText,
    textSecondary: '#A0A0A0',
    background: colors.darkBackground,
    surface: '#1E262F',
    border: '#2A323A',
    ...colors
  }
};