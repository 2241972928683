import React from 'react';
import styled from 'styled-components';
import { FiMail, FiExternalLink } from 'react-icons/fi';

const FooterContainer = styled.footer`
  background-color: ${({ theme }) => theme.background.secondary};
  padding: ${({ theme }) => theme.spacing.lg};
  margin-top: auto;
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.md};
  width: 100%;
  padding: 0 ${({ theme }) => theme.spacing.md};
  
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const FooterSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.sm};
`;

const FooterLogo = styled.h3`
  color: ${({ theme }) => theme.text.accent};
  margin-bottom: ${({ theme }) => theme.spacing.xs};
`;

const FooterText = styled.p`
  color: ${({ theme }) => theme.text.secondary};
  margin: 0;
  font-size: ${({ theme }) => theme.typography.fontSizes.sm};
`;

const FooterLink = styled.a`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xs};
  color: ${({ theme }) => theme.text.primary};
  text-decoration: none;
  font-size: ${({ theme }) => theme.typography.fontSizes.sm};
  
  &:hover {
    color: ${({ theme }) => theme.text.accent};
    text-decoration: none;
  }
`;

const Copyright = styled.div`
  text-align: center;
  padding-top: ${({ theme }) => theme.spacing.md};
  color: ${({ theme }) => theme.text.secondary};
  font-size: ${({ theme }) => theme.typography.fontSizes.sm};
  border-top: 1px solid ${({ theme }) => theme.border};
  margin-top: ${({ theme }) => theme.spacing.md};
`;

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();
  
  return (
    <FooterContainer>
      <FooterContent>
        <FooterSection>
          <FooterLogo>Mandjet</FooterLogo>
          <FooterText>
            Ecosystem Risk Analysis for Supply Chains
          </FooterText>
        </FooterSection>
        
        <FooterSection>
          <FooterLink href="mailto:chris@alpinesustains.com">
            <FiMail /> Contact
          </FooterLink>
          <FooterLink href="https://alpinesustains.com" target="_blank" rel="noopener noreferrer">
            <FiExternalLink /> Website
          </FooterLink>
        </FooterSection>
      </FooterContent>
      
      <Copyright>
        © {currentYear} Mandjet. All rights reserved.
      </Copyright>
    </FooterContainer>
  );
};

export default Footer;