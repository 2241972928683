import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

// Firebase configuration for alpinesustains.com
const firebaseConfig = {
  apiKey: "AIzaSyBy2FNvjeowWReWEH_BWfIqMLBJL8583vY",
  authDomain: "alpineclimate-c074f.firebaseapp.com",
  projectId: "alpineclimate-c074f",
  storageBucket: "alpineclimate-c074f.firebasestorage.app",
  messagingSenderId: "198239908929",
  appId: "1:198239908929:web:e8f3f3315013add9db82c0",
  measurementId: "G-Q7KV4DYCFK"
};

// Initialize Firebase with auth domain config to support GitHub Codespaces and localhost with any port
if (typeof window !== 'undefined' && window.location) {
  if (window.location.hostname.includes('github.dev') || window.location.hostname === 'localhost') {
    firebaseConfig.authDomain = window.location.hostname;
    
    // For localhost, we need to add the port as well
    if (window.location.hostname === 'localhost' && window.location.port) {
      firebaseConfig.authDomain = `${window.location.hostname}:${window.location.port}`;
    }
  }
}

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Initialize Firebase services
const firebaseAuth = getAuth(firebaseApp);
const firebaseDb = getFirestore(firebaseApp);
const firebaseStorage = getStorage(firebaseApp);

// Export the services
export const app = firebaseApp;
export const auth = firebaseAuth;
export const db = firebaseDb;
export const storage = firebaseStorage;

export default firebaseApp;