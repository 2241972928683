import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FiAlertTriangle, FiDollarSign, FiTrendingDown, FiBarChart2, FiDownload } from 'react-icons/fi';
import { 
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,
  AreaChart, Area, LineChart, Line, PieChart, Pie, Cell
} from 'recharts';
import { Product, SourceType, SupplyChainInput, YieldForecast, FinancialImpact } from '../../types';
import Card from '../../components/ui/Card';
import Button from '../../components/ui/Button';

const RiskAnalysisContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.lg};
`;

const HeaderSection = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.lg};
`;

const Heading = styled.h1`
  font-size: ${({ theme }) => theme.typography.fontSizes.heading};
  color: ${({ theme }) => theme.text.primary};
  margin-bottom: ${({ theme }) => theme.spacing.md};
`;

const Subheading = styled.p`
  color: ${({ theme }) => theme.text.secondary};
  max-width: 800px;
`;

const FormSection = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.xl};
`;

const FormCard = styled(Card)`
  padding: ${({ theme }) => theme.spacing.lg};
`;

const FormTitle = styled.h2`
  font-size: ${({ theme }) => theme.typography.fontSizes.xl};
  color: ${({ theme }) => theme.text.primary};
  margin-bottom: ${({ theme }) => theme.spacing.md};
`;

const FormGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: ${({ theme }) => theme.spacing.md};
  
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const FormGroup = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.md};
`;

const Label = styled.label`
  display: block;
  margin-bottom: ${({ theme }) => theme.spacing.xs};
  color: ${({ theme }) => theme.text.primary};
  font-weight: ${({ theme }) => theme.typography.fontWeights.semiBold};
`;

const Input = styled.input`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.sm};
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: ${({ theme }) => theme.borders.radius.md};
  background-color: ${({ theme }) => theme.background.primary};
  color: ${({ theme }) => theme.text.primary};
  
  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.text.accent};
  }
`;

const Select = styled.select`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.sm};
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: ${({ theme }) => theme.borders.radius.md};
  background-color: ${({ theme }) => theme.background.primary};
  color: ${({ theme }) => theme.text.primary};
  
  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.text.accent};
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.md};
  margin-top: ${({ theme }) => theme.spacing.lg};
  
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

// Results section
const ResultsSection = styled.div`
  margin-top: ${({ theme }) => theme.spacing.xl};
`;

const ResultsSummary = styled(Card)`
  padding: ${({ theme }) => theme.spacing.lg};
  margin-bottom: ${({ theme }) => theme.spacing.lg};
  border-left: 4px solid ${({ theme }) => theme.text.accent};
`;

const SummaryTitle = styled.h2`
  font-size: ${({ theme }) => theme.typography.fontSizes.xl};
  color: ${({ theme }) => theme.text.primary};
  margin-bottom: ${({ theme }) => theme.spacing.md};
`;

const SummaryText = styled.p`
  color: ${({ theme }) => theme.text.secondary};
  margin-bottom: ${({ theme }) => theme.spacing.md};
`;

const MetricsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: ${({ theme }) => theme.spacing.md};
  margin: ${({ theme }) => theme.spacing.lg} 0;
  
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const MetricCard = styled(Card)`
  padding: ${({ theme }) => theme.spacing.md};
`;

const MetricIcon = styled.div<{ color: string }>`
  width: 48px;
  height: 48px;
  border-radius: ${({ theme }) => theme.borders.radius.md};
  background-color: ${({ color }) => color};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacing.sm};
  
  svg {
    color: white;
    font-size: 24px;
  }
`;

const MetricLabel = styled.div`
  color: ${({ theme }) => theme.text.secondary};
  font-size: ${({ theme }) => theme.typography.fontSizes.sm};
  margin-bottom: ${({ theme }) => theme.spacing.xxs};
`;

const MetricValue = styled.div`
  color: ${({ theme }) => theme.text.primary};
  font-size: ${({ theme }) => theme.typography.fontSizes.xl};
  font-weight: ${({ theme }) => theme.typography.fontWeights.semiBold};
`;

const ChartsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${({ theme }) => theme.spacing.lg};
  
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const ChartCard = styled(Card)`
  padding: ${({ theme }) => theme.spacing.md};
  height: 100%;
`;

const ChartTitle = styled.h3`
  font-size: ${({ theme }) => theme.typography.fontSizes.lg};
  color: ${({ theme }) => theme.text.primary};
  margin-bottom: ${({ theme }) => theme.spacing.md};
`;

const ChartContainer = styled.div`
  height: 300px;
  width: 100%;
`;

const AdaptationList = styled.ul`
  margin-top: ${({ theme }) => theme.spacing.md};
  padding-left: ${({ theme }) => theme.spacing.lg};
  
  li {
    margin-bottom: ${({ theme }) => theme.spacing.sm};
    color: ${({ theme }) => theme.text.secondary};
  }
`;

// Dummy data for products
const dummyProducts: Product[] = [
  { id: '1', name: 'Yellowfin Tuna', category: 'Marine', scientificName: 'Thunnus albacares' },
  { id: '2', name: 'Atlantic Salmon', category: 'Marine', scientificName: 'Salmo salar' },
  { id: '3', name: 'Wine Grapes (Cabernet)', category: 'Plant', scientificName: 'Vitis vinifera' },
  { id: '4', name: 'Timber - Oak', category: 'Plant', scientificName: 'Quercus alba' },
  { id: '5', name: 'Coffee Beans (Arabica)', category: 'Plant', scientificName: 'Coffea arabica' },
  { id: '6', name: 'Pacific Cod', category: 'Marine', scientificName: 'Gadus macrocephalus' },
  { id: '7', name: 'Beef Cattle', category: 'Animal', scientificName: 'Bos taurus' },
  { id: '8', name: 'Almond', category: 'Plant', scientificName: 'Prunus dulcis' }
];

// Chart dummy data
const yieldForecastData = [
  { year: '2025', yield: 95 },
  { year: '2026', yield: 93 },
  { year: '2027', yield: 88 },
  { year: '2028', yield: 85 },
  { year: '2029', yield: 82 },
  { year: '2030', yield: 80 },
  { year: '2031', yield: 77 },
  { year: '2032', yield: 75 },
  { year: '2033', yield: 73 },
  { year: '2034', yield: 72 },
  { year: '2035', yield: 70 }
];

const costImpactData = [
  { year: '2025', cost: 105 },
  { year: '2026', cost: 110 },
  { year: '2027', cost: 115 },
  { year: '2028', cost: 120 },
  { year: '2029', cost: 125 },
  { year: '2030', cost: 130 },
  { year: '2031', cost: 133 },
  { year: '2032', cost: 137 },
  { year: '2033', cost: 140 },
  { year: '2034', cost: 143 },
  { year: '2035', cost: 145 }
];

const habitatIndexData = [
  { name: 'Suitable', value: 65 },
  { name: 'At Risk', value: 35 }
];

const COLORS = ['#17826A', '#D83A34'];

const regionImpactData = [
  { region: 'Southeast Asia', impact: 85 },
  { region: 'East Africa', impact: 73 },
  { region: 'Mediterranean', impact: 68 },
  { region: 'South America', impact: 62 },
  { region: 'North Atlantic', impact: 57 }
];

const RiskAnalysis: React.FC = () => {
  const [products, setProducts] = useState<Product[]>(dummyProducts);
  const [selectedProduct, setSelectedProduct] = useState<string>('');
  const [sourceType, setSourceType] = useState<SourceType>('Wild');
  const [quantity, setQuantity] = useState<string>('');
  const [unit, setUnit] = useState<string>('');
  const [pricePerUnit, setPricePerUnit] = useState<string>('');
  const [currency, setCurrency] = useState<string>('USD');
  const [region, setRegion] = useState<string>('');
  const [showResults, setShowResults] = useState(false);
  
  const handleProductChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedProduct(e.target.value);
  };
  
  const handleSourceTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSourceType(e.target.value as SourceType);
  };
  
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    // In a real app, this would call an API to process the analysis
    // For demo purposes, we'll just show the results section
    setShowResults(true);
    
    // Scroll to results section
    setTimeout(() => {
      const resultsElement = document.getElementById('results-section');
      if (resultsElement) {
        resultsElement.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };
  
  const handleReset = () => {
    setSelectedProduct('');
    setSourceType('Wild');
    setQuantity('');
    setUnit('');
    setPricePerUnit('');
    setCurrency('USD');
    setRegion('');
    setShowResults(false);
  };
  
  const handleDownloadReport = () => {
    // In a real app, this would generate and download a PDF report
    alert('In a production environment, this would generate and download a detailed PDF report.');
  };
  
  // Get the selected product details
  const productDetails = products.find(p => p.id === selectedProduct);
  
  return (
    <RiskAnalysisContainer>
      <HeaderSection>
        <Heading>Ecosystem Risk Analysis</Heading>
        <Subheading>
          Analyze climate change impacts on your supply chain using our species-specific ecosystem risk model.
          Enter your supply chain details below to generate a comprehensive risk assessment.
        </Subheading>
      </HeaderSection>
      
      <FormSection>
        <FormCard>
          <FormTitle>Supply Chain Input</FormTitle>
          
          <form onSubmit={handleSubmit}>
            <FormGrid>
              <FormGroup>
                <Label htmlFor="product">Product</Label>
                <Select 
                  id="product" 
                  value={selectedProduct} 
                  onChange={handleProductChange}
                  required
                >
                  <option value="">Select a product</option>
                  {products.map(product => (
                    <option key={product.id} value={product.id}>
                      {product.name} ({product.scientificName})
                    </option>
                  ))}
                </Select>
              </FormGroup>
              
              <FormGroup>
                <Label htmlFor="sourceType">Source Type</Label>
                <Select 
                  id="sourceType" 
                  value={sourceType} 
                  onChange={handleSourceTypeChange}
                  required
                >
                  <option value="Wild">Wild</option>
                  <option value="Controlled">Controlled</option>
                  <option value="Farmed">Farmed</option>
                </Select>
              </FormGroup>
              
              <FormGroup>
                <Label htmlFor="quantity">Procurement Quantity</Label>
                <Input 
                  id="quantity" 
                  type="number" 
                  min="1"
                  value={quantity} 
                  onChange={(e) => setQuantity(e.target.value)}
                  placeholder="e.g., 50" 
                  required
                />
              </FormGroup>
              
              <FormGroup>
                <Label htmlFor="unit">Unit</Label>
                <Input 
                  id="unit" 
                  type="text" 
                  value={unit} 
                  onChange={(e) => setUnit(e.target.value)}
                  placeholder="e.g., tunas, kg, tons" 
                  required
                />
              </FormGroup>
              
              <FormGroup>
                <Label htmlFor="pricePerUnit">Price per Unit</Label>
                <Input 
                  id="pricePerUnit" 
                  type="number"
                  min="0.01" 
                  step="0.01"
                  value={pricePerUnit} 
                  onChange={(e) => setPricePerUnit(e.target.value)}
                  placeholder="e.g., 100.00" 
                  required
                />
              </FormGroup>
              
              <FormGroup>
                <Label htmlFor="currency">Currency</Label>
                <Select 
                  id="currency" 
                  value={currency} 
                  onChange={(e) => setCurrency(e.target.value)}
                  required
                >
                  <option value="USD">USD ($)</option>
                  <option value="EUR">EUR (€)</option>
                  <option value="GBP">GBP (£)</option>
                  <option value="JPY">JPY (¥)</option>
                </Select>
              </FormGroup>
              
              <FormGroup>
                <Label htmlFor="region">Sourcing Region</Label>
                <Input 
                  id="region" 
                  type="text" 
                  value={region} 
                  onChange={(e) => setRegion(e.target.value)}
                  placeholder="e.g., Southeast Asia" 
                  required
                />
              </FormGroup>
            </FormGrid>
            
            <ButtonGroup>
              <Button type="submit" variant="primary">
                Run Analysis
              </Button>
              <Button type="button" variant="tertiary" onClick={handleReset}>
                Reset Form
              </Button>
            </ButtonGroup>
          </form>
        </FormCard>
      </FormSection>
      
      {showResults && productDetails && (
        <ResultsSection id="results-section">
          <ResultsSummary>
            <SummaryTitle>Analysis Results</SummaryTitle>
            <SummaryText>
              Our ecosystem risk analysis for <strong>{productDetails.name}</strong> from <strong>{region}</strong> indicates a projected 30% decline in yield over the next 10 years. This will likely result in increased procurement costs and supply constraints. Below are the detailed findings and recommendations.
            </SummaryText>
            
            <Button 
              variant="secondary" 
              size="sm" 
              leftIcon={<FiDownload />} 
              onClick={handleDownloadReport}
            >
              Download Full Report
            </Button>
          </ResultsSummary>
          
          <MetricsGrid>
            <MetricCard>
              <MetricIcon color="#D83A34">
                <FiTrendingDown />
              </MetricIcon>
              <MetricLabel>Yield Change (10yr)</MetricLabel>
              <MetricValue>-30%</MetricValue>
            </MetricCard>
            
            <MetricCard>
              <MetricIcon color="#F5CB5C">
                <FiAlertTriangle />
              </MetricIcon>
              <MetricLabel>Habitat Suitability Index</MetricLabel>
              <MetricValue>65/100</MetricValue>
            </MetricCard>
            
            <MetricCard>
              <MetricIcon color="#17826A">
                <FiDollarSign />
              </MetricIcon>
              <MetricLabel>Projected Price Increase</MetricLabel>
              <MetricValue>+45%</MetricValue>
            </MetricCard>
            
            <MetricCard>
              <MetricIcon color="#29587D">
                <FiBarChart2 />
              </MetricIcon>
              <MetricLabel>Supply Chain Risk Score</MetricLabel>
              <MetricValue>72/100</MetricValue>
            </MetricCard>
          </MetricsGrid>
          
          <ChartsGrid>
            <ChartCard>
              <ChartTitle>Projected Yield Changes (2025-2035)</ChartTitle>
              <ChartContainer>
                <ResponsiveContainer width="100%" height="100%">
                  <AreaChart
                    data={yieldForecastData}
                    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="year" />
                    <YAxis 
                      domain={[60, 100]} 
                      tickFormatter={(value) => `${value}%`} 
                    />
                    <Tooltip 
                      formatter={(value) => [`${value}%`, 'Yield']} 
                      labelFormatter={(label) => `Year: ${label}`}
                    />
                    <Area 
                      type="monotone" 
                      dataKey="yield" 
                      stroke="#17826A" 
                      fill="#17826A" 
                      fillOpacity={0.3} 
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </ChartContainer>
            </ChartCard>
            
            <ChartCard>
              <ChartTitle>Projected Cost Impact (2025-2035)</ChartTitle>
              <ChartContainer>
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart
                    data={costImpactData}
                    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="year" />
                    <YAxis 
                      domain={[100, 150]} 
                      tickFormatter={(value) => `${value}%`} 
                    />
                    <Tooltip 
                      formatter={(value) => [`${value}%`, 'Cost']} 
                      labelFormatter={(label) => `Year: ${label}`}
                    />
                    <Line 
                      type="monotone" 
                      dataKey="cost" 
                      stroke="#D83A34" 
                      strokeWidth={2} 
                      dot={{ stroke: '#D83A34', strokeWidth: 2, r: 4 }}
                      activeDot={{ stroke: '#D83A34', strokeWidth: 2, r: 6 }}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </ChartContainer>
            </ChartCard>
            
            <ChartCard>
              <ChartTitle>Habitat Suitability Assessment</ChartTitle>
              <ChartContainer>
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={habitatIndexData}
                      cx="50%"
                      cy="50%"
                      innerRadius={60}
                      outerRadius={90}
                      paddingAngle={5}
                      dataKey="value"
                      label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
                    >
                      {habitatIndexData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip formatter={(value) => [`${value}%`, 'Proportion']} />
                  </PieChart>
                </ResponsiveContainer>
              </ChartContainer>
            </ChartCard>
            
            <ChartCard>
              <ChartTitle>Regional Risk Comparison</ChartTitle>
              <ChartContainer>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    data={regionImpactData}
                    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="region" />
                    <YAxis domain={[0, 100]} />
                    <Tooltip formatter={(value) => [`${value}/100`, 'Risk Score']} />
                    <Bar 
                      dataKey="impact" 
                      fill="#29587D" 
                      radius={[4, 4, 0, 0]} 
                    />
                  </BarChart>
                </ResponsiveContainer>
              </ChartContainer>
            </ChartCard>
          </ChartsGrid>
          
          <div style={{ marginTop: '2rem' }}>
            <Card>
              <div style={{ padding: '1.5rem' }}>
                <h2 style={{ marginBottom: '1rem' }}>Recommended Adaptation Strategies</h2>
                <p>Based on our analysis, we recommend the following strategies to mitigate supply chain risks:</p>
            
                <AdaptationList>
                  <li>Diversify sourcing regions to include areas with more stable habitat projections.</li>
                  <li>Invest in partnerships with suppliers using sustainable harvesting/farming practices.</li>
                  <li>Develop contingency plans for price volatility and supply disruptions.</li>
                  <li>Consider vertical integration or long-term contracts to stabilize supply.</li>
                  <li>Explore alternative products with similar characteristics but lower ecosystem risk profiles.</li>
                </AdaptationList>
              </div>
            </Card>
          </div>
        </ResultsSection>
      )}
    </RiskAnalysisContainer>
  );
};

export default RiskAnalysis;