import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import Layout from './Layout';

const PrivateRoute: React.FC = () => {
  const { isAuthenticated, isLoading, isApproved, isAnonymous } = useAuth();
  
  // Wait until authentication state is determined
  if (isLoading) {
    return (
      <div style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh' 
      }}>
        <p>Loading...</p>
      </div>
    );
  }
  
  // If user is not authenticated, redirect to login
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }
  
  // If user is authenticated but not approved and not anonymous, redirect to approval pending page
  if (isAuthenticated && !isApproved && !isAnonymous) {
    return <Navigate to="/approval-pending" />;
  }
  
  // If user is authenticated and approved, render the protected route with Layout
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

export default PrivateRoute;