import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FiClock, FiLogOut } from 'react-icons/fi';
import { useAuth } from '../../contexts/AuthContext';
import { signOut } from '../../firebase/auth';
import Button from '../../components/ui/Button';
import Card from '../../components/ui/Card';

const PendingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.background.primary};
  padding: ${({ theme }) => theme.spacing.lg};
`;

const PendingCard = styled(Card)`
  max-width: 500px;
  width: 100%;
  text-align: center;
  padding: ${({ theme }) => theme.spacing.xl};
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacing.lg};
  
  svg {
    font-size: 4rem;
    color: ${({ theme }) => theme.colors.warning};
  }
`;

const Title = styled.h1`
  font-size: ${({ theme }) => theme.typography.fontSizes.xxl};
  color: ${({ theme }) => theme.text.primary};
  margin-bottom: ${({ theme }) => theme.spacing.md};
`;

const Description = styled.p`
  color: ${({ theme }) => theme.text.secondary};
  margin-bottom: ${({ theme }) => theme.spacing.lg};
`;

const ContactInfo = styled.div`
  padding: ${({ theme }) => theme.spacing.md};
  background-color: ${({ theme }) => theme.background.secondary};
  border-radius: ${({ theme }) => theme.borders.radius.md};
  margin-bottom: ${({ theme }) => theme.spacing.lg};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ApprovalPending: React.FC = () => {
  const { currentUser, isAuthenticated, isApproved } = useAuth();
  const navigate = useNavigate();
  
  // If user is approved, redirect to dashboard
  useEffect(() => {
    if (isAuthenticated && isApproved) {
      navigate('/');
    }
    
    // If not authenticated, redirect to login
    if (!isAuthenticated) {
      navigate('/login');
    }
  }, [isAuthenticated, isApproved, navigate]);
  
  const handleLogout = async () => {
    try {
      await signOut();
      navigate('/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };
  
  return (
    <PendingContainer>
      <PendingCard variant="elevated">
        <IconWrapper>
          <FiClock />
        </IconWrapper>
        
        <Title>Approval Pending</Title>
        
        <Description>
          Thank you for registering. Your access request has been submitted and is pending approval by an administrator.
        </Description>
        
        <ContactInfo>
          <p>If you need immediate access or have questions, please contact:</p>
          <p><strong>chris@alpinesustains.com</strong></p>
        </ContactInfo>
        
        <ButtonContainer>
          <Button 
            variant="tertiary" 
            leftIcon={<FiLogOut />} 
            onClick={handleLogout}
          >
            Sign Out
          </Button>
        </ButtonContainer>
      </PendingCard>
    </PendingContainer>
  );
};

export default ApprovalPending;