import React, { useState, useEffect, useCallback } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { 
  FiChevronLeft, 
  FiChevronRight, 
  FiMaximize, 
  FiMinimize, 
  FiDownload, 
  FiTrendingUp, 
  FiTarget, 
  FiAlertTriangle,
  FiCheckCircle,
  FiDatabase,
  FiAward,
  FiDollarSign,
  FiUsers,
  FiPieChart,
  FiLinkedin,
  FiGlobe
} from 'react-icons/fi';
import { getPitchDeck } from '../../firebase/firestore';
import { PitchDeckSlide } from '../../types';
import Button from '../../components/ui/Button';
import Card from '../../components/ui/Card';

// Styled components for the Pitch Deck
const PitchDeckContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.md};
  height: 100%;
  background-color: ${({ theme }) => theme.colors.background};
  padding: ${({ theme }) => theme.spacing.md};
`;

const PitchDeckHeader = styled.div`
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing.sm};
  max-width: 800px;
  margin: 0 auto;
  
  @media (max-width: 768px) {
    padding: 0 ${({ theme }) => theme.spacing.md};
  }
`;

const HeaderTitle = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.sm};
  
  h1 {
    font-size: ${({ theme }) => theme.typography.fontSizes.xxl};
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: ${({ theme }) => theme.spacing.xs};
    font-weight: 600;
  }
  
  p {
    color: ${({ theme }) => theme.colors.textSecondary};
    font-size: ${({ theme }) => theme.typography.fontSizes.md};
    line-height: 1.4;
    max-width: 600px;
    margin: 0 auto;
  }
`;

const HeaderActions = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.sm};
  justify-content: center;
  
  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
  }
`;

const CarouselContainer = styled(Card)<{ isFullScreen: boolean }>`
  position: ${({ isFullScreen }) => isFullScreen ? 'fixed' : 'relative'};
  top: ${({ isFullScreen }) => isFullScreen ? '0' : 'auto'};
  left: ${({ isFullScreen }) => isFullScreen ? '0' : 'auto'};
  right: ${({ isFullScreen }) => isFullScreen ? '0' : 'auto'};
  bottom: ${({ isFullScreen }) => isFullScreen ? '0' : 'auto'};
  width: ${({ isFullScreen }) => isFullScreen ? '100vw' : '100%'};
  height: ${({ isFullScreen }) => isFullScreen ? '100vh' : 'calc(100vh - 160px)'};
  min-height: 500px;
  z-index: ${({ isFullScreen }) => isFullScreen ? '1000' : '1'};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0;
  background-color: ${({ theme }) => theme.colors.background};
  box-shadow: ${({ isFullScreen, theme }) => isFullScreen ? theme.shadow : 'none'};
  border-radius: ${({ isFullScreen, theme }) => isFullScreen ? theme.borders.radius.lg : '0'};
  border: ${({ isFullScreen }) => isFullScreen ? 'none' : '1px solid rgba(0,0,0,0.05)'};
`;

const SlideContainer = styled.div`
  flex: 1;
  position: relative;
  overflow: hidden;
  touch-action: none;
  user-select: none;
  background-color: ${({ theme }) => theme.colors.background};
`;

const NavButton = styled.button<{ position: 'left' | 'right' }>`
  position: absolute;
  top: 50%;
  ${({ position }) => position === 'left' ? 'left: 8px;' : 'right: 8px;'}
  transform: translateY(-50%);
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.8);
  color: ${({ theme }) => theme.colors.primary};
  border: none;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  
  &:hover {
    background-color: rgba(255, 255, 255, 0.95);
    transform: translateY(-50%) scale(1.05);
  }
  
  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
  
  svg {
    font-size: 24px;
  }
  
  @media (max-width: 768px) {
    width: 36px;
    height: 36px;
    
    svg {
      font-size: 18px;
    }
  }
`;

const Slide = styled.div<{ active: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: ${({ active }) => active ? 1 : 0};
  transition: opacity 0.3s ease-in-out;
  pointer-events: ${({ active }) => active ? 'all' : 'none'};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.xl};
  overflow-y: auto;
  background-color: ${({ theme }) => theme.colors.background};
`;

const SlideTitle = styled.h2`
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: ${({ theme }) => theme.spacing.lg};
  font-size: ${({ theme }) => theme.typography.fontSizes.xxxl};
  text-align: center;
  font-weight: 600;
`;

const SlideIcon = styled.div`
  width: 96px;
  height: 96px;
  border-radius: 50%;
  background-color: rgba(23, 130, 106, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacing.xl};
  
  svg {
    font-size: 48px;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const SlideContent = styled.div`
  max-width: 900px;
  width: 100%;
  text-align: center;
`;

const SlideDescription = styled.p`
  color: ${({ theme }) => theme.colors.text};
  font-size: ${({ theme }) => theme.typography.fontSizes.xl};
  line-height: 1.7;
  margin-bottom: ${({ theme }) => theme.spacing.xl};
`;

// New styled components for better team member formatting
const Name = styled.div`
  font-weight: bold;
  font-size: ${({ theme }) => theme.typography.fontSizes.lg};
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: ${({ theme }) => theme.spacing.xxs};
`;

const Bio = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.sm};
  color: ${({ theme }) => theme.colors.text};
  font-size: ${({ theme }) => theme.typography.fontSizes.md};
  line-height: 1.4;
`;

const ProfileLink = styled.a`
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  font-size: ${({ theme }) => theme.typography.fontSizes.sm};
  
  &:hover {
    text-decoration: underline;
  }
  
  svg {
    margin-right: ${({ theme }) => theme.spacing.xxs};
  }
`;

const TeamMember = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.md};
  text-align: left;
`;

const TeamGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: ${({ theme }) => theme.spacing.md};
  margin-bottom: ${({ theme }) => theme.spacing.lg};
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const SlidePoints = styled.ul`
  text-align: left;
  margin-top: ${({ theme }) => theme.spacing.lg};
  padding-left: ${({ theme }) => theme.spacing.lg};
  
  li {
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.typography.fontSizes.lg};
    margin-bottom: ${({ theme }) => theme.spacing.md};
    line-height: 1.6;
  }
`;

const Footnote = styled.div`
  font-size: ${({ theme }) => theme.typography.fontSizes.sm};
  color: ${({ theme }) => theme.colors.textSecondary};
  font-style: italic;
  text-align: left;
  margin-top: ${({ theme }) => theme.spacing.md};
`;

const SlideNavigation = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.md}`};
  border-top: 1px solid rgba(0,0,0,0.05);
  background-color: ${({ theme }) => theme.colors.background};
`;

const SlideControls = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.sm};
`;

const SlideIndicators = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.xxs};
`;

const SlideIndicator = styled.button<{ active: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ active, theme }) => 
    active ? theme.colors.primary : 'rgba(0,0,0,0.1)'};
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
  
  &:hover {
    background-color: ${({ active, theme }) => 
      active ? theme.colors.primary : 'rgba(0,0,0,0.2)'};
  }
`;

const SlideInfo = styled.div`
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: ${({ theme }) => theme.typography.fontSizes.sm};
`;

// Define types for the custom slides
type CustomSlide = {
  id: string;
  title: string;
  icon: React.ReactNode;
  order: number;
  description: string;
  points?: string[];
  customContent?: React.ReactNode;
  footnote?: string;
  footnote2?: string;
};

// Updated slides with detailed content
const customSlides: CustomSlide[] = [
  {
    id: '1',
    title: 'Mandjet',
    icon: <FiDatabase />,
    order: 1,
    description: "Mapping and forecasting ecosystem risks to help consultants and analysts quickly understand both operational and financial impacts on supply chains.",
    points: [
      "Ask a porfolio manager to quantify the impact of a marine heatwave on their portfolio"
    ]
  },
  {
    id: '9',
    title: 'Partners & Advisors',
    icon: <FiUsers />,
    order: 3,
    description: 'Our expert team ready to deliver results',
    customContent: (
      <>
        <TeamGrid>
          <TeamMember>
            <Name>Christopher DuMont</Name>
            <Bio>Founder committed to unlocking hidden ecosystem risks and building a breakthrough product in the climate risk space.</Bio>
            <ProfileLink href="https://linkedin.com/in/dumontchristopher" target="_blank" rel="noopener noreferrer">
              <FiLinkedin /> LinkedIn
            </ProfileLink>
          </TeamMember>
          
          <TeamMember>
            <Name>Meg Anderson</Name>
            <Bio>Former BlackRock FIG Analyst with firsthand experience on financial impacts of climate risks.</Bio>
            <ProfileLink href="https://linkedin.com/in/meg-s-anderson" target="_blank" rel="noopener noreferrer">
              <FiLinkedin /> LinkedIn
            </ProfileLink>
          </TeamMember>

          <TeamMember>
            <Name>Zoe Thierfelder</Name>
            <Bio>Strategic partnerships lead, driving data acquisition and market expansion.</Bio>
            <ProfileLink href="https://linkedin.com/in/zoe-thierfelder-246179226" target="_blank" rel="noopener noreferrer">
              <FiLinkedin /> LinkedIn
            </ProfileLink>
          </TeamMember>

          <TeamMember>
            <Name>Climate Adaptation Works</Name>
            <Bio>Partner consultant supporting risk methodology development with extensive vertical expertise.</Bio>
            <ProfileLink href="https://climateadaptationworks.com" target="_blank" rel="noopener noreferrer">
              <FiGlobe /> Website
            </ProfileLink>
          </TeamMember>

          <TeamMember>
            <Name>Anjela Arora</Name>
            <Bio>Fractional data strategy lead ensuring critical data integration for actionable insights.</Bio>
            <ProfileLink href="https://linkedin.com/in/anjela-arora" target="_blank" rel="noopener noreferrer">
              <FiLinkedin /> LinkedIn
            </ProfileLink>
          </TeamMember>
        </TeamGrid>
        <Bio style={{ fontStyle: 'italic' }}>Additional experts are being finalized to join our growing team.</Bio>
      </>
    )
  },
  {
    id: '2',
    title: 'Market Opportunity',
    icon: <FiTrendingUp />,
    order: 4,
    description: 'A rapidly growing market driven by climate uncertainty.',
    points: [
      'Global climate risk analytics market estimated at $7.9B by 2028',
      'Increasing regulatory pressure for climate disclosure',
      '78% of businesses lack species-specific risk insights*',
      'SAM (what we can capture): Focusing on North America, we aim to capture $20-$50M of the market short-term**',
    ],
    footnote: '* Based on recent industry surveys and market research reports',
    footnote2: '** This number is based on our low pricing scheme and capturing 5-12% of the market short-term. Happy to walk you through it',
  },
  {
    id: '3',
    title: 'The Problem',
    icon: <FiAlertTriangle />,
    order: 5,
    description: 'Current approaches miss species-specific risks to supply chains.',
    points: [
      'Consultants, advisors, and analysts spend weeks researching species vulnerability data',
      'Generic risk assessments without species context',
      'Limited visibility into how climate affects specific supply chain components',
      'Disconnected data sources hinder comprehensive analysis'
    ]
  },
  {
    id: '4',
    title: 'Our Solution',
    icon: <FiCheckCircle />,
    order: 6,
    description: 'Comprehensive species-specific yield forecasting with financial risk insights.',
    points: [
      'Integrates biological data with climate models',
      'Reduces research time from weeks to hours',
      'Provides customized risk assessments for any supply chain',
      'Delivers both operational and financial risk analytics for consultants, advisors, and analysts'
    ]
  },
  {
    id: '5',
    title: 'Our Platform',
    icon: <FiDatabase />,
    order: 7,
    description: 'A powerful analytics engine for supply chain and operations analysts.',
    points: [
      'Comprehensive integration of ecological data sources',
      'Forecasting species-specific sensitivities to changes in the climate ',
      'Intuitive dashboard for rapid insights',
      'Exportable reports for client presentations'
    ]
  },
  {
    id: '6',
    title: 'Competitive Advantage',
    icon: <FiAward />,
    order: 8,
    description: 'What makes Mandjet uniquely valuable.',
    points: [
      'Only platform focused on species-specific yield forecasting with financial insights',
      'Integration of ecological and climate data',
      'Designed specifically for consultants, advisors, and analysts',
      'Reduces time completing forecasts for environmental risk impacts'
    ]
  },
  {
    id: '7',
    title: 'Business Model',
    icon: <FiDollarSign />,
    order: 9,
    description: 'Simple, scalable subscription model.',
    points: [
      'SaaS subscription with tiered pricing based on usage',
      'Enterprise plans for major consulting firms',
      'Additional revenue from custom report generation',
      'API access to our data and analysis within the near-future'
    ]
  },
  {
    id: '11',
    title: 'Roadmap & Ask',
    icon: <FiTarget />,
    order: 10,
    description: 'Our strategic plan and ask to accelerate growth.',
    points: [
      'Initially focusing on species-specific risks due to our strong expertise and network',
      '1-year focus on pilot adoption and product refinement',
      '3-year expansion into key markets with strategic partnerships',
      '5-year goal to dominate the ecosystem risk analytics niche and expand to other verticals often overlooked from competitors',
      'Since we are still validating the concept and developing partnerships, we would just like to keep you all updated for now. If your know any innovative companies (hardware, data providers, etc.) that we should partner with for data or for them to utilize our analytics capacbilities, we would love to connect with them.'
    ]
  }
];

const getIcon = (index: number) => {
  const slide = customSlides[index];
  return slide ? slide.icon : <FiDatabase />;
};

const PitchDeck: React.FC = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [touchStartX, setTouchStartX] = useState(0);
  
  const location = useLocation();
  const navigate = useNavigate();
  
  useEffect(() => {
    // Check if the URL has a specific slide parameter
    const queryParams = new URLSearchParams(location.search);
    const slideParam = queryParams.get('slide');
    
    if (slideParam && !isNaN(Number(slideParam))) {
      setCurrentSlide(Number(slideParam) - 1);
    }
  }, [location.search]);
  
  const handleNextSlide = useCallback(() => {
    if (currentSlide < customSlides.length - 1) {
      const nextSlide = currentSlide + 1;
      setCurrentSlide(nextSlide);
      updateUrlWithSlide(nextSlide + 1);
    }
  }, [currentSlide]);
  
  const handlePrevSlide = useCallback(() => {
    if (currentSlide > 0) {
      const prevSlide = currentSlide - 1;
      setCurrentSlide(prevSlide);
      updateUrlWithSlide(prevSlide + 1);
    }
  }, [currentSlide]);
  
  const handleSlideClick = (index: number) => {
    setCurrentSlide(index);
    updateUrlWithSlide(index + 1);
  };
  
  const updateUrlWithSlide = (slideNumber: number) => {
    const newUrl = `${location.pathname}?slide=${slideNumber}`;
    navigate(newUrl, { replace: true });
  };
  
  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };
  
  const handleTouchStart = (e: React.TouchEvent) => {
    setTouchStartX(e.touches[0].clientX);
  };
  
  const handleTouchEnd = (e: React.TouchEvent) => {
    const touchEndX = e.changedTouches[0].clientX;
    const diffX = touchEndX - touchStartX;
    
    // If the swipe is significant enough (more than 50px)
    if (Math.abs(diffX) > 50) {
      if (diffX > 0) {
        handlePrevSlide();
      } else {
        handleNextSlide();
      }
    }
  };
  
  const handleKeyDown = useCallback((e: KeyboardEvent) => {
    if (e.key === 'ArrowRight') {
      handleNextSlide();
    } else if (e.key === 'ArrowLeft') {
      handlePrevSlide();
    } else if (e.key === 'Escape' && isFullScreen) {
      setIsFullScreen(false);
    }
  }, [handleNextSlide, handlePrevSlide, isFullScreen]);
  
  // Add keyboard event listeners
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);
  
  if (isLoading) {
    return <div>Loading...</div>;
  }
  
  return (
    <PitchDeckContainer>
      <PitchDeckHeader>
        <HeaderTitle>
          <h1>Mandjet Pitch Deck</h1>
          <p>Navigate through our presentation using the arrows or slide indicators.</p>
        </HeaderTitle>
      </PitchDeckHeader>
      
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '0.5rem' }}>
        <HeaderActions>
          <Button 
            variant="tertiary" 
            size="sm" 
            leftIcon={isFullScreen ? <FiMinimize /> : <FiMaximize />} 
            onClick={toggleFullScreen}
          >
            {isFullScreen ? 'Exit Fullscreen' : 'Fullscreen'}
          </Button>
        </HeaderActions>
      </div>
      
      <CarouselContainer isFullScreen={isFullScreen} variant="elevated" noPadding>
        <SlideContainer 
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
        >
          <NavButton 
            position="left" 
            onClick={handlePrevSlide}
            disabled={currentSlide === 0}
            aria-label="Previous slide"
          >
            <FiChevronLeft />
          </NavButton>
          
          <NavButton 
            position="right" 
            onClick={handleNextSlide}
            disabled={currentSlide === customSlides.length - 1}
            aria-label="Next slide"
          >
            <FiChevronRight />
          </NavButton>
          
          {customSlides.map((slide, index) => (
            <Slide key={slide.id} active={currentSlide === index}>
              <SlideTitle>{slide.title}</SlideTitle>
              <SlideIcon>{getIcon(index)}</SlideIcon>
              <SlideContent>
                <SlideDescription>{slide.description}</SlideDescription>
                {slide.customContent ? (
                  slide.customContent
                ) : (
                <SlidePoints>
                    {slide.points?.map((point, i) => (
                    <li key={i}>{point}</li>
                  ))}
                </SlidePoints>
                )}
                {slide.footnote && <Footnote>{slide.footnote}</Footnote>}
              </SlideContent>
            </Slide>
          ))}
        </SlideContainer>
        
        <SlideNavigation>
          <SlideInfo>
            Slide {currentSlide + 1} of {customSlides.length}
          </SlideInfo>
          
          <SlideIndicators>
            {customSlides.map((_, index) => (
              <SlideIndicator 
                key={index} 
                active={currentSlide === index} 
                onClick={() => handleSlideClick(index)} 
                aria-label={`Go to slide ${index + 1}`}
              />
            ))}
          </SlideIndicators>
          
          <SlideControls>
            <Button 
              variant="tertiary" 
              size="sm" 
              leftIcon={<FiChevronLeft />} 
              onClick={handlePrevSlide}
              disabled={currentSlide === 0}
            >
              Prev
            </Button>
            
            <Button 
              variant="tertiary" 
              size="sm" 
              rightIcon={<FiChevronRight />} 
              onClick={handleNextSlide}
              disabled={currentSlide === customSlides.length - 1}
            >
              Next
            </Button>
          </SlideControls>
        </SlideNavigation>
      </CarouselContainer>
    </PitchDeckContainer>
  );
};

export default PitchDeck;
