import React, { useState } from 'react';
import styled from 'styled-components';
import { 
  FiSearch, 
  FiPlay, 
  FiBarChart2, 
  FiTrendingDown, 
  FiMapPin,
  FiArrowRight,
  FiChevronDown,
  FiPieChart,
  FiTrendingUp
} from 'react-icons/fi';
import Card from '../../components/ui/Card';
import Button from '../../components/ui/Button';

// Styled components for the walkthrough page
const WalkthroughContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xl};
`;

// Hero section
const HeroSection = styled.div`
  position: relative;
  padding: ${({ theme }) => theme.spacing.xxl} ${({ theme }) => theme.spacing.lg};
  border-radius: ${({ theme }) => theme.borders.radius.lg};
  overflow: hidden;
  margin-bottom: ${({ theme }) => theme.spacing.xl};
  color: white;
  text-align: center;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(135deg, ${({ theme }) => theme.colors.deepNavy} 0%, ${({ theme }) => theme.colors.oceanBlue} 100%);
    z-index: -2;
  }
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('https://images.unsplash.com/photo-1488188840666-e2308741a1cc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1950&q=80');
    background-size: cover;
    background-position: center;
    opacity: 0.2;
    z-index: -1;
  }
`;

const HeroTitle = styled.h1`
  font-size: 3.5rem;
  font-weight: 300;
  margin-bottom: ${({ theme }) => theme.spacing.md};
  max-width: 800px;
`;

const HeroSubtitle = styled.p`
  font-size: ${({ theme }) => theme.typography.fontSizes.xl};
  margin-bottom: ${({ theme }) => theme.spacing.lg};
  max-width: 700px;
`;

const HeroButton = styled(Button)`
  padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.lg}`};
  font-size: ${({ theme }) => theme.typography.fontSizes.lg};
  transition: all 0.3s ease;
  
  &:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }
`;

// Scenario section
const ScenarioSection = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.xl};
`;

const ScenarioIntro = styled.div`
  background-color: ${({ theme }) => theme.background.secondary};
  padding: ${({ theme }) => theme.spacing.lg};
  border-radius: ${({ theme }) => theme.borders.radius.md};
  margin-bottom: ${({ theme }) => theme.spacing.lg};
`;

const ScenarioTitle = styled.h2`
  font-size: ${({ theme }) => theme.typography.fontSizes.xxl};
  color: ${({ theme }) => theme.text.primary};
  margin-bottom: ${({ theme }) => theme.spacing.md};
`;

const ScenarioDescription = styled.p`
  color: ${({ theme }) => theme.text.secondary};
  font-size: ${({ theme }) => theme.typography.fontSizes.lg};
  line-height: 1.6;
`;

// Split layout
const SplitLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${({ theme }) => theme.spacing.lg};
  
  @media (min-width: 992px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const SectionPanel = styled.div`
  background-color: ${({ theme }) => theme.background.card};
  border-radius: ${({ theme }) => theme.borders.radius.md};
  padding: ${({ theme }) => theme.spacing.lg};
  box-shadow: ${({ theme }) => theme.shadow};
`;

const PanelTitle = styled.h3`
  font-size: ${({ theme }) => theme.typography.fontSizes.xl};
  color: ${({ theme }) => theme.text.primary};
  margin-bottom: ${({ theme }) => theme.spacing.md};
  display: flex;
  align-items: center;
  
  svg {
    margin-right: ${({ theme }) => theme.spacing.sm};
  }
`;

const SearchContainer = styled.div`
  position: relative;
  margin-bottom: ${({ theme }) => theme.spacing.lg};
`;

const SearchIcon = styled.span`
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: ${({ theme }) => theme.text.secondary};
`;

const SearchInput = styled.select`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.lg};
  padding-left: 40px;
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: ${({ theme }) => theme.borders.radius.md};
  background-color: ${({ theme }) => theme.background.primary};
  color: ${({ theme }) => theme.text.primary};
  font-size: ${({ theme }) => theme.typography.fontSizes.md};
  appearance: none;
  
  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.text.accent};
    box-shadow: 0 0 0 3px rgba(23, 130, 106, 0.1);
  }
`;

// Analysis card
const AnalysisCard = styled(Card)`
  margin-top: ${({ theme }) => theme.spacing.md};
  padding: ${({ theme }) => theme.spacing.md};
  transition: all 0.3s ease;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: ${({ theme }) => theme.hoverShadow};
  }
`;

const MetricsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${({ theme }) => theme.spacing.sm};
  margin-bottom: ${({ theme }) => theme.spacing.md};
`;

const Metric = styled.div`
  background-color: ${({ theme }) => theme.background.secondary};
  padding: ${({ theme }) => theme.spacing.sm};
  border-radius: ${({ theme }) => theme.borders.radius.sm};
`;

const MetricLabel = styled.div`
  font-size: ${({ theme }) => theme.typography.fontSizes.sm};
  color: ${({ theme }) => theme.text.secondary};
  margin-bottom: ${({ theme }) => theme.spacing.xxs};
`;

const MetricValue = styled.div`
  font-size: ${({ theme }) => theme.typography.fontSizes.lg};
  font-weight: ${({ theme }) => theme.typography.fontWeights.semiBold};
  color: ${({ theme }) => theme.text.primary};
`;

const MapContainer = styled.div`
  width: 100%;
  height: 200px;
  background-color: ${({ theme }) => theme.background.secondary};
  border-radius: ${({ theme }) => theme.borders.radius.md};
  margin-top: ${({ theme }) => theme.spacing.md};
  position: relative;
  overflow: hidden;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('https://images.unsplash.com/photo-1569336415962-a4bd9f69c8bf?ixlib=rb-1.2.1&auto=format&fit=crop&w=1489&q=80');
    background-size: cover;
    background-position: center;
    opacity: 0.3;
  }
`;

const MapOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.md};
  color: ${({ theme }) => theme.text.primary};
  font-weight: ${({ theme }) => theme.typography.fontWeights.semiBold};
`;

// Supply chain form
const SupplyChainForm = styled.div`
  margin-top: ${({ theme }) => theme.spacing.xl};
  padding: ${({ theme }) => theme.spacing.lg};
  background-color: ${({ theme }) => theme.background.secondary};
  border-radius: ${({ theme }) => theme.borders.radius.md};
`;

const FormTitle = styled.h3`
  font-size: ${({ theme }) => theme.typography.fontSizes.xl};
  color: ${({ theme }) => theme.text.primary};
  margin-bottom: ${({ theme }) => theme.spacing.md};
`;

const FormGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: ${({ theme }) => theme.spacing.md};
  
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const FormGroup = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.md};
`;

const Label = styled.label`
  display: block;
  margin-bottom: ${({ theme }) => theme.spacing.xs};
  color: ${({ theme }) => theme.text.primary};
  font-weight: ${({ theme }) => theme.typography.fontWeights.semiBold};
`;

const Input = styled.input`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.sm};
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: ${({ theme }) => theme.borders.radius.md};
  background-color: ${({ theme }) => theme.background.primary};
  color: ${({ theme }) => theme.text.primary};
  
  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.text.accent};
  }
`;

const Select = styled.select`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.sm};
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: ${({ theme }) => theme.borders.radius.md};
  background-color: ${({ theme }) => theme.background.primary};
  color: ${({ theme }) => theme.text.primary};
  
  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.text.accent};
  }
`;

const ToggleGroup = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.md};
  margin-bottom: ${({ theme }) => theme.spacing.md};
`;

const ToggleButton = styled.button<{ active: boolean }>`
  flex: 1;
  padding: ${({ theme }) => theme.spacing.sm};
  background-color: ${({ active, theme }) => active ? theme.text.accent : theme.background.primary};
  color: ${({ active, theme }) => active ? 'white' : theme.text.primary};
  border: 1px solid ${({ active, theme }) => active ? theme.text.accent : theme.border};
  border-radius: ${({ theme }) => theme.borders.radius.md};
  font-weight: ${({ theme }) => theme.typography.fontWeights.semiBold};
  cursor: pointer;
  transition: all 0.2s ease;
  
  &:hover {
    background-color: ${({ active, theme }) => active ? theme.text.accent : theme.background.secondary};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing.lg};
`;

const RunButton = styled(Button)`
  min-width: 200px;
`;

// Results section
const ResultsSection = styled.div`
  margin-top: ${({ theme }) => theme.spacing.xl};
  display: ${({ hidden }: { hidden: boolean }) => hidden ? 'none' : 'block'};
`;

const ResultsTitle = styled.h2`
  font-size: ${({ theme }) => theme.typography.fontSizes.xxl};
  color: ${({ theme }) => theme.text.primary};
  margin-bottom: ${({ theme }) => theme.spacing.lg};
  text-align: center;
`;

const ResultsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: ${({ theme }) => theme.spacing.lg};
  margin-bottom: ${({ theme }) => theme.spacing.xl};
  
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

// New styled components for charts
const ChartContainer = styled.div`
  width: 100%;
  height: 200px;
  background-color: ${({ theme }) => theme.background.secondary};
  border-radius: ${({ theme }) => theme.borders.radius.md};
  margin-top: ${({ theme }) => theme.spacing.md};
  padding: ${({ theme }) => theme.spacing.md};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const FakeBarChart = styled.div`
  display: flex;
  align-items: flex-end;
  height: 120px;
  justify-content: space-between;
  padding: 0 10px;
`;

const Bar = styled.div<{ height: string }>`
  width: 30px;
  height: ${props => props.height};
  background: linear-gradient(to top, ${({ theme }) => theme.colors.primary}, ${({ theme }) => theme.colors.primaryLight});
  border-radius: 4px 4px 0 0;
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 11px;
    color: ${({ theme }) => theme.text.secondary};
  }
`;

const FakeLineChart = styled.div`
  position: relative;
  height: 120px;
  width: 100%;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, 
      transparent 0%, transparent 5%,
      ${({ theme }) => theme.colors.primary}30 5%, ${({ theme }) => theme.colors.primary}30 6%,
      transparent 6%, transparent 10%,
      ${({ theme }) => theme.colors.primary}30 10%, ${({ theme }) => theme.colors.primary}30 11%,
      transparent 11%, transparent 15%,
      ${({ theme }) => theme.colors.primary}30 15%, ${({ theme }) => theme.colors.primary}30 16%,
      transparent 16%, transparent 20%,
      ${({ theme }) => theme.colors.primary}30 20%, ${({ theme }) => theme.colors.primary}30 21%,
      transparent 21%, transparent 25%,
      ${({ theme }) => theme.colors.primary}30 25%, ${({ theme }) => theme.colors.primary}30 26%,
      transparent 26%, transparent 30%,
      ${({ theme }) => theme.colors.primary}30 30%, ${({ theme }) => theme.colors.primary}30 31%,
      transparent 31%
    );
  }
  
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 3px;
    background: ${({ theme }) => theme.colors.primary};
    z-index: 1;
    transform: translateY(-50%);
    clip-path: polygon(
      0% 0%, 10% 100%, 20% 50%, 
      30% 30%, 40% 70%, 50% 20%, 
      60% 40%, 70% 60%, 80% 10%, 
      90% 50%, 100% 0%
    );
  }
`;

const ChartTitle = styled.div`
  font-size: ${({ theme }) => theme.typography.fontSizes.sm};
  color: ${({ theme }) => theme.text.secondary};
  margin-bottom: ${({ theme }) => theme.spacing.xs};
`;

const DemoDisclaimer = styled.div`
  background-color: ${({ theme }) => theme.colors.warningLight};
  border-radius: ${({ theme }) => theme.borders.radius.md};
  padding: ${({ theme }) => theme.spacing.md};
  margin-bottom: ${({ theme }) => theme.spacing.lg};
  font-style: italic;
  color: ${({ theme }) => theme.colors.warning};
  border-left: 4px solid ${({ theme }) => theme.colors.warning};
`;

// Add missing styled components
const SummaryCard = styled(Card)`
  padding: ${({ theme }) => theme.spacing.lg};
  margin-top: ${({ theme }) => theme.spacing.lg};
`;

const SummaryTitle = styled.h3`
  font-size: ${({ theme }) => theme.typography.fontSizes.xl};
  color: ${({ theme }) => theme.text.primary};
  margin-bottom: ${({ theme }) => theme.spacing.md};
`;

const SummaryText = styled.p`
  color: ${({ theme }) => theme.text.secondary};
  line-height: 1.6;
  margin-bottom: ${({ theme }) => theme.spacing.md};
`;

const RecommendationsList = styled.ul`
  margin-top: ${({ theme }) => theme.spacing.md};
  padding-left: ${({ theme }) => theme.spacing.lg};
  
  li {
    margin-bottom: ${({ theme }) => theme.spacing.sm};
    color: ${({ theme }) => theme.text.secondary};
  }
`;

// Mock data
const marineSpecies = [
  { id: '1', name: 'Yellowfin Tuna', region: 'Southeast Asia' },
  { id: '2', name: 'Atlantic Salmon', region: 'North Atlantic' },
  { id: '3', name: 'Oysters', region: 'Pacific Northwest' },
  { id: '4', name: 'Shrimp', region: 'Gulf of Mexico' },
  { id: '5', name: 'Sea Scallops', region: 'East Coast USA' }
];

const terrestrialSpecies = [
  { id: '1', name: 'Wine Grapes', region: 'Mediterranean' },
  { id: '2', name: 'Blueberries', region: 'Pacific Northwest' },
  { id: '3', name: 'Oak Trees', region: 'North America' },
  { id: '4', name: 'Strawberries', region: 'California' },
  { id: '5', name: 'Cranberries', region: 'New England' }
];

const Walkthrough: React.FC = () => {
  const [marineSelected, setMarineSelected] = useState('');
  const [terrestrialSelected, setTerrestrialSelected] = useState('');
  const [sourceType, setSourceType] = useState<'Wild' | 'Controlled'>('Wild');
  const [volume, setVolume] = useState('');
  const [price, setPrice] = useState('');
  const [showResults, setShowResults] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  
  const handleMarineChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setMarineSelected(e.target.value);
  };
  
  const handleTerrestrialChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTerrestrialSelected(e.target.value);
  };
  
  const handleExploreClick = () => {
    setIsScrolled(true);
    const scenarioSection = document.getElementById('scenario-section');
    if (scenarioSection) {
      scenarioSection.scrollIntoView({ behavior: 'smooth' });
    }
  };
  
  const handleRunAnalysis = () => {
    // Show loading state (in a real app)
    
    // After a brief delay, show results
    setTimeout(() => {
      setShowResults(true);
      
      // Scroll to results
      const resultsSection = document.getElementById('results-section');
      if (resultsSection) {
        resultsSection.scrollIntoView({ behavior: 'smooth' });
      }
    }, 1000);
  };
  
  const getSelectedMarine = () => {
    return marineSpecies.find(s => s.id === marineSelected);
  };
  
  const getSelectedTerrestrial = () => {
    return terrestrialSpecies.find(s => s.id === terrestrialSelected);
  };
  
  return (
    <WalkthroughContainer>
      {/* Hero Section */}
      <HeroSection>
        <HeroTitle>Mandjet: Ecosystem Risk at Your Fingertips</HeroTitle>
        <HeroSubtitle>Discover how climate change impacts your supply chain from ocean to orchard.</HeroSubtitle>
        <HeroButton 
          variant="primary" 
          size="lg" 
          onClick={handleExploreClick}
          leftIcon={<FiPlay />}
        >
          Explore Demo
        </HeroButton>
      </HeroSection>
      
      {/* Scenario Section */}
      <ScenarioSection id="scenario-section">
        <ScenarioIntro>
          <ScenarioTitle>Interactive Business Scenario</ScenarioTitle>
          <ScenarioDescription>
            Explore how ecosystem risk analysis can improve your supply chain resilience and financial planning.
          </ScenarioDescription>
          <DemoDisclaimer>
            Note: This interactive Business Scenario is completely made up and designed to demonstrate why this data and analysis is important. 
            Our actual models are not included in this demo, and everything is currently dynamic for demonstration purposes only.
          </DemoDisclaimer>
        </ScenarioIntro>
        
        <SplitLayout>
          {/* Marine Panel */}
          <SectionPanel>
            <PanelTitle>
              <FiMapPin /> Marine Species Impact
            </PanelTitle>
            <SearchContainer>
              <SearchIcon><FiChevronDown /></SearchIcon>
              <SearchInput
                value={marineSelected}
                onChange={handleMarineChange}
              >
                <option value="">Select a marine species</option>
                <option value="atlantic_cod">Atlantic Cod</option>
                <option value="bluefin_tuna">Bluefin Tuna</option>
                <option value="alaskan_pollock">Alaskan Pollock</option>
                <option value="pacific_salmon">Pacific Salmon</option>
                <option value="maine_lobster">Maine Lobster</option>
              </SearchInput>
            </SearchContainer>
            
            {/* Add fake chart */}
            <ChartContainer>
              <ChartTitle>Projected Population Changes (5-year)</ChartTitle>
              <FakeBarChart>
                <Bar height="60%" />
                <Bar height="50%" />
                <Bar height="40%" />
                <Bar height="35%" />
                <Bar height="30%" />
              </FakeBarChart>
            </ChartContainer>
            
            {marineSelected && (
              <AnalysisCard>
                <h4>{getSelectedMarine()?.name}</h4>
                <MetricsGrid>
                  <Metric>
                    <MetricLabel>Yield Forecast</MetricLabel>
                    <MetricValue>-20%</MetricValue>
                  </Metric>
                  <Metric>
                    <MetricLabel>Habitat Suitability</MetricLabel>
                    <MetricValue>72/100</MetricValue>
                  </Metric>
                </MetricsGrid>
                <p>Projected 20% yield drop over 10 years under RCP4.5 scenario.</p>
                <MapContainer>
                  <MapOverlay>
                    <FiMapPin size={24} />
                    <div>Sourcing Region: {getSelectedMarine()?.region}</div>
                  </MapOverlay>
                </MapContainer>
                
                {/* Add a line chart */}
                <ChartContainer>
                  <ChartTitle>Yield Forecast (10-year trend)</ChartTitle>
                  <FakeLineChart />
                </ChartContainer>
              </AnalysisCard>
            )}
          </SectionPanel>
          
          {/* Terrestrial Panel */}
          <SectionPanel>
            <PanelTitle>
              <FiMapPin /> Terrestrial Species Impact
            </PanelTitle>
            <SearchContainer>
              <SearchIcon><FiChevronDown /></SearchIcon>
              <SearchInput
                value={terrestrialSelected}
                onChange={handleTerrestrialChange}
              >
                <option value="">Select a terrestrial species</option>
                <option value="arabica_coffee">Arabica Coffee</option>
                <option value="cacao_tree">Cacao Tree</option>
                <option value="rubber_tree">Rubber Tree</option>
                <option value="honey_bee">Honey Bee</option>
                <option value="wine_grape">Wine Grape</option>
              </SearchInput>
            </SearchContainer>
            
            {/* Add fake chart */}
            <ChartContainer>
              <ChartTitle>Climate Sensitivity Index</ChartTitle>
              <FakeBarChart>
                <Bar height="80%" />
                <Bar height="65%" />
                <Bar height="75%" />
                <Bar height="45%" />
                <Bar height="60%" />
              </FakeBarChart>
            </ChartContainer>
            
            {terrestrialSelected && (
              <AnalysisCard>
                <h4>{getSelectedTerrestrial()?.name}</h4>
                <MetricsGrid>
                  <Metric>
                    <MetricLabel>Yield Forecast</MetricLabel>
                    <MetricValue>-15%</MetricValue>
                  </Metric>
                  <Metric>
                    <MetricLabel>Habitat Suitability</MetricLabel>
                    <MetricValue>78/100</MetricValue>
                  </Metric>
                </MetricsGrid>
                <p>Projected 15% yield reduction over 10 years under RCP8.5 scenario.</p>
                <MapContainer>
                  <MapOverlay>
                    <FiMapPin size={24} />
                    <div>Sourcing Region: {getSelectedTerrestrial()?.region}</div>
                  </MapOverlay>
                </MapContainer>
                
                {/* Add a line chart */}
                <ChartContainer>
                  <ChartTitle>Projected Habitat Suitability</ChartTitle>
                  <FakeLineChart />
                </ChartContainer>
              </AnalysisCard>
            )}
          </SectionPanel>
        </SplitLayout>
        
        {/* Supply Chain Form */}
        {(marineSelected || terrestrialSelected) && (
          <SupplyChainForm>
            <FormTitle>Supply Chain Impact Simulation</FormTitle>
            <FormGrid>
              <FormGroup>
                <Label htmlFor="volume">Procurement Volume</Label>
                <Input
                  id="volume"
                  type="text"
                  value={volume}
                  onChange={(e) => setVolume(e.target.value)}
                  placeholder={marineSelected ? "e.g., 50 tunas/day" : "e.g., 20 tons/season"}
                />
              </FormGroup>
              
              <FormGroup>
                <Label htmlFor="price">Current Price per Unit</Label>
                <Input
                  id="price"
                  type="text"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  placeholder={marineSelected ? "e.g., $200 per tuna" : "e.g., $2,500 per ton"}
                />
              </FormGroup>
              
              <FormGroup>
                <Label htmlFor="region">Sourcing Region</Label>
                <Input
                  id="region"
                  type="text"
                  value={marineSelected ? getSelectedMarine()?.region : getSelectedTerrestrial()?.region}
                  readOnly
                />
              </FormGroup>
              
              <FormGroup>
                <Label>Source Type</Label>
                <ToggleGroup>
                  <ToggleButton
                    type="button"
                    active={sourceType === 'Wild'}
                    onClick={() => setSourceType('Wild')}
                  >
                    Wild
                  </ToggleButton>
                  <ToggleButton
                    type="button"
                    active={sourceType === 'Controlled'}
                    onClick={() => setSourceType('Controlled')}
                  >
                    Controlled/Farmed
                  </ToggleButton>
                </ToggleGroup>
              </FormGroup>
            </FormGrid>
            
            <ButtonContainer>
              <RunButton
                variant="primary"
                leftIcon={<FiBarChart2 />}
                onClick={handleRunAnalysis}
                disabled={!marineSelected && !terrestrialSelected}
              >
                Run Analysis
              </RunButton>
            </ButtonContainer>
          </SupplyChainForm>
        )}
      </ScenarioSection>
      
      {/* Results Section */}
      <ResultsSection id="results-section" hidden={!showResults}>
        <ResultsTitle>Risk Dashboard</ResultsTitle>
        
        <ResultsGrid>
          <Card>
            <PanelTitle>
              <FiTrendingDown /> Yield Forecast Results
            </PanelTitle>
            {marineSelected && (
              <AnalysisCard>
                <h4>{getSelectedMarine()?.name}</h4>
                <p>Projected yield reduction of 20%</p>
                <p>Supply Chain Impact: Estimated 25% increase in price resulting in an additional daily cost of $2,500</p>
                <ChartContainer>
                  [Historical vs. Forecasted Yield Trend Chart]
                </ChartContainer>
              </AnalysisCard>
            )}
            {terrestrialSelected && (
              <AnalysisCard>
                <h4>{getSelectedTerrestrial()?.name}</h4>
                <p>Projected yield reduction of 15%</p>
                <p>Supply Chain Impact: Estimated 18% increase in price resulting in an additional seasonal cost of $9,000</p>
                <ChartContainer>
                  [Historical vs. Forecasted Yield Trend Chart]
                </ChartContainer>
              </AnalysisCard>
            )}
          </Card>
          
          <Card>
            <PanelTitle>
              <FiBarChart2 /> Environmental Factors
            </PanelTitle>
            <ChartContainer>
              [Comparison Chart: Marine vs. Terrestrial]
            </ChartContainer>
            <AnalysisCard>
              <h4>Key Environmental Factors</h4>
              <MetricsGrid>
                <Metric>
                  <MetricLabel>Temperature Change</MetricLabel>
                  <MetricValue>+1.8°C</MetricValue>
                </Metric>
                <Metric>
                  <MetricLabel>Ocean Acidity</MetricLabel>
                  <MetricValue>+12%</MetricValue>
                </Metric>
                <Metric>
                  <MetricLabel>Precipitation</MetricLabel>
                  <MetricValue>-8%</MetricValue>
                </Metric>
                <Metric>
                  <MetricLabel>Extreme Events</MetricLabel>
                  <MetricValue>+22%</MetricValue>
                </Metric>
              </MetricsGrid>
            </AnalysisCard>
          </Card>
        </ResultsGrid>
        
        <SummaryCard>
          <SummaryTitle>Analysis Summary & Recommendations</SummaryTitle>
          <SummaryText>
            Based on current projections, your seafood supply chain faces significant risk from declining {marineSelected ? getSelectedMarine()?.name : 'marine species'} yields, while your agricultural produce is expected to experience moderate challenges. Consider implementing the following strategies to mitigate these risks:
          </SummaryText>
          <RecommendationsList>
            <li>Diversify suppliers across different geographical regions to reduce regional climate risk exposure.</li>
            <li>Negotiate long-term contracts with current suppliers to stabilize pricing as yields decline.</li>
            <li>Invest in controlled environment production methods for your most at-risk products.</li>
            <li>Develop contingency plans for alternative products that may be less vulnerable to climate impacts.</li>
            <li>Monitor real-time ecosystem health indicators to anticipate supply disruptions before they occur.</li>
          </RecommendationsList>
        </SummaryCard>
      </ResultsSection>
    </WalkthroughContainer>
  );
};

export default Walkthrough;