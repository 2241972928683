import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FiBookmark, FiInfo, FiFileText, FiChevronDown, FiTrendingUp, FiShield, FiClipboard, FiBarChart2, FiArrowDown, FiLinkedin } from 'react-icons/fi';
import { getUpdates } from '../../firebase/firestore';
import { Update } from '../../types';
import Card from '../../components/ui/Card';
import Button from '../../components/ui/Button';

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.lg};
  position: relative;
`;

const HeaderSection = styled.div`
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing.xxl};
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
`;

const Heading = styled.h1`
  font-size: ${({ theme }) => theme.typography.fontSizes.xxxl};
  color: ${({ theme }) => theme.colors.text};
  margin-bottom: ${({ theme }) => theme.spacing.md};
  font-weight: 600;
`;

const Subheading = styled.p`
  color: ${({ theme }) => theme.colors.textSecondary};
  margin-bottom: ${({ theme }) => theme.spacing.lg};
  font-size: ${({ theme }) => theme.typography.fontSizes.lg};
  line-height: 1.6;
`;

const SectionContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.xl};
  scroll-margin-top: 80px;
`;

const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.md};
`;

const SectionTitle = styled.h2`
  font-size: ${({ theme }) => theme.typography.fontSizes.xxl};
  color: ${({ theme }) => theme.colors.text};
  font-weight: 600;
`;

const InfoSection = styled.div`
  background-color: ${({ theme }) => theme.colors.surface};
  border-radius: ${({ theme }) => theme.borders.radius.md};
  padding: ${({ theme }) => theme.spacing.xl};
  margin-bottom: ${({ theme }) => theme.spacing.xl};
  border: 1px solid ${({ theme }) => theme.colors.border};
`;

const InfoTitle = styled.h3`
  font-size: ${({ theme }) => theme.typography.fontSizes.xl};
  color: ${({ theme }) => theme.colors.text};
  margin-bottom: ${({ theme }) => theme.spacing.md};
  font-weight: 600;
`;

const InfoContent = styled.p`
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: ${({ theme }) => theme.typography.fontSizes.md};
  line-height: 1.7;
  margin-bottom: ${({ theme }) => theme.spacing.md};
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.md};
  margin-top: ${({ theme }) => theme.spacing.lg};
  flex-wrap: wrap;
  justify-content: center;
`;

const UpdatesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${({ theme }) => theme.spacing.md};
  
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (min-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const UpdateCard = styled(Card)`
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: ${({ theme }) => theme.shadow};
  }
`;

const UpdateHeader = styled.div`
  padding: ${({ theme }) => theme.spacing.md};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
`;

const UpdateTitle = styled.h3`
  font-size: ${({ theme }) => theme.typography.fontSizes.lg};
  margin-bottom: ${({ theme }) => theme.spacing.xs};
  color: ${({ theme }) => theme.colors.text};
  font-weight: 600;
`;

const UpdateDate = styled.div`
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: ${({ theme }) => theme.typography.fontSizes.sm};
`;

const UpdateContent = styled.div`
  padding: ${({ theme }) => theme.spacing.md};
  flex-grow: 1;
  color: ${({ theme }) => theme.colors.textSecondary};
  line-height: 1.6;
`;

const LoadingMessage = styled.div`
  text-align: center;
  padding: ${({ theme }) => theme.spacing.xl};
  color: ${({ theme }) => theme.colors.textSecondary};
`;

const EmptyState = styled.div`
  text-align: center;
  padding: ${({ theme }) => theme.spacing.xl};
  color: ${({ theme }) => theme.colors.textSecondary};
`;

const FeatureCard = styled(Card)`
  padding: ${({ theme }) => theme.spacing.lg};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing.md};
  transition: transform 0.3s ease;
  
  &:hover {
    transform: translateY(-5px);
  }
`;

const FeatureIcon = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: rgba(23, 130, 106, 0.1); /* Using a semi-transparent version of primary */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacing.md};
  
  svg {
    color: ${({ theme }) => theme.colors.primary};
    font-size: 24px;
  }
`;

const FeatureTitle = styled.h3`
  font-size: ${({ theme }) => theme.typography.fontSizes.lg};
  color: ${({ theme }) => theme.colors.text};
  margin-bottom: ${({ theme }) => theme.spacing.sm};
  font-weight: 600;
`;

const FeatureDescription = styled.p`
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: ${({ theme }) => theme.typography.fontSizes.md};
  line-height: 1.6;
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${({ theme }) => theme.spacing.lg};
  margin-top: ${({ theme }) => theme.spacing.xl};
  
  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const BenefitsList = styled.ul`
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: ${({ theme }) => theme.typography.fontSizes.md};
  line-height: 1.7;
  padding-left: ${({ theme }) => theme.spacing.xl};
  margin-bottom: ${({ theme }) => theme.spacing.lg};
  
  li {
    margin-bottom: ${({ theme }) => theme.spacing.md};
  }
`;

const UpdatesButton = styled(Button)`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1001;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  
  svg {
    margin: 0;
  }
`;

const WhyNotSection = styled(InfoSection)`
  background: linear-gradient(135deg, rgba(23, 130, 106, 0.1) 0%, rgba(23, 130, 106, 0.05) 100%);
  border: 1px solid rgba(23, 130, 106, 0.2);
`;

const PlatformSection = styled(InfoSection)`
  background: linear-gradient(135deg, rgba(93, 95, 239, 0.1) 0%, rgba(93, 95, 239, 0.05) 100%);
  border: 1px solid rgba(93, 95, 239, 0.2);
`;

const LinkedInLink = styled.a`
  display: inline-flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;
  font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
  margin-top: ${({ theme }) => theme.spacing.sm};
  
  &:hover {
    text-decoration: underline;
  }
  
  svg {
    margin-right: ${({ theme }) => theme.spacing.xs};
  }
`;

const Dashboard: React.FC = () => {
  const [updates, setUpdates] = useState<Update[]>([]);
  const [loading, setLoading] = useState(true);
  const updatesRef = useRef<HTMLDivElement>(null);
  
  useEffect(() => {
    const fetchUpdates = async () => {
      try {
        const updatesData = await getUpdates(5); // Fetch 5 instead of 6 to make room for our hardcoded update
        
        // Convert timestamps to Date objects
        const processedUpdates = updatesData.map((update: any) => ({
          ...update,
          date: update.date?.toDate() || new Date(),
        }));
        
        // Add our hardcoded update about Zoe
        const zoeUpdate: Update = {
          id: 'zoe-announcement',
          title: 'Welcome to Our Newest Team Member!',
          content: 'Say hi to Zoe - our latest founding team member! With experience building platforms in the sustainability space, she will support getting that granular data through partnerships and translating it into meaningful commercial value for our clients.',
          date: new Date('2025-04-02'),
          linkedInUrl: 'https://linkedin.com/in/zoe-thierfelder-246179226'
        };
        
        // Add the Zoe update at the beginning of the array
        setUpdates([zoeUpdate, ...processedUpdates]);
      } catch (error) {
        console.error('Error fetching updates:', error);
      } finally {
        setLoading(false);
      }
    };
    
    fetchUpdates();
  }, []);
  
  const formatDate = (date: Date) => {
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    }).format(date);
  };
  
  return (
    <DashboardContainer>
      <UpdatesButton 
        variant="primary" 
        onClick={() => updatesRef.current?.scrollIntoView({ behavior: 'smooth' })} 
        title="View Latest Updates"
        aria-label="View Latest Updates"
      >
        <FiArrowDown />
      </UpdatesButton>
      
      <HeaderSection>
        <Heading>Welcome to Mandjet</Heading>
        <Subheading>
          Mandjet provides species-specific yield forecasting to help consultants, advisors, and analysts analyze climate-related disruptions to supply chains efficiently and accurately.
        </Subheading>
      </HeaderSection>
      
      <InfoSection>
        <InfoTitle>Our Mission</InfoTitle>
        <InfoContent>
          Mandjet empowers supply chain consultants, operational risk advisors, and analysts with advanced, time-saving analytics. Our mission is to eliminate weeks of manual research by delivering actionable climate risk insights in a fraction of the time required by traditional methods.
        </InfoContent>
        <InfoContent>
          By harnessing integrated ecological data and advanced climate models, we enable any analyst, investor, or consultant to rapidly assess how climate change will affect critical species and, in turn, the resilience of their clients' supply chains.
        </InfoContent>
        <ButtonGroup>
          <Link to="/pitch-deck">
            <Button 
              variant="primary"
              size="large"
            >
              View Pitch Deck
            </Button>
          </Link>
        </ButtonGroup>
      </InfoSection>
      
      <PlatformSection>
        <InfoTitle>Our Platform</InfoTitle>
        <InfoContent>
          Our platform seamlessly combines comprehensive ecological data with climate and financial risk modeling to deliver high-precision yield forecasts and risk assessments. This holistic approach equips our clients with the tools they need to identify vulnerabilities, seize emerging opportunities, and make informed decisions that drive business sustainability and growth.
        </InfoContent>
      </PlatformSection>
      
      <SectionContainer>
        <SectionHeader>
          <SectionTitle>Benefits</SectionTitle>
        </SectionHeader>
        
        <FeaturesGrid>
          <FeatureCard>
            <FeatureIcon>
              <FiTrendingUp />
            </FeatureIcon>
            <FeatureTitle>For Consultants</FeatureTitle>
            <FeatureDescription>
              Deliver deeper insights to clients with species-specific data that traditional risk models miss
            </FeatureDescription>
          </FeatureCard>
          
          <FeatureCard>
            <FeatureIcon>
              <FiShield />
            </FeatureIcon>
            <FeatureTitle>For Risk Advisors</FeatureTitle>
            <FeatureDescription>
              Quantify climate risk exposure with greater precision and develop more effective mitigation strategies
            </FeatureDescription>
          </FeatureCard>
          
          <FeatureCard>
            <FeatureIcon>
              <FiBarChart2 />
            </FeatureIcon>
            <FeatureTitle>For Analysts</FeatureTitle>
            <FeatureDescription>
              Access comprehensive species forecasts in moments instead of relying on in-person analysis of an asset.
            </FeatureDescription>
          </FeatureCard>
        </FeaturesGrid>
      </SectionContainer>
      
      <WhyNotSection>
        <InfoTitle>Why Now?</InfoTitle>
        <InfoContent>
          Now is the time to adopt next-generation climate risk analytics:
        </InfoContent>
        <BenefitsList>
          <li>
            <strong>Regulatory Imperatives:</strong> With increasing pressure in both Europe and the US for companies to disclose climate risks to shareholders, a robust analytics solution is no longer optional—it's becoming a regulatory necessity.
          </li>
          <li>
            <strong>Market Gap:</strong> Despite a growing awareness of climate risks, many organizations still rely on outdated, on-the-ground assessments for secondary climate risks. This gap leaves room for a solution that provides deep, species-specific insights.
          </li>
          <li>
            <strong>Evolving Risk Landscape:</strong> As climate change accelerates, the complexity and scale of secondary risks continue to grow. Traditional methods simply cannot keep pace with the dynamic nature of these threats.
          </li>
          <li>
            <strong>Proactive Strategy:</strong> By leveraging advanced analytics, Mandjet offers a future-proof solution that not only addresses today's challenges but also adapts to emerging trends, ensuring that our clients remain ahead of the curve in managing climate-related disruptions.
          </li>
        </BenefitsList>
      </WhyNotSection>
      
      <SectionContainer ref={updatesRef}>
        <SectionHeader>
          <SectionTitle>Latest Updates</SectionTitle>
        </SectionHeader>
        
        {loading ? (
          <LoadingMessage>Loading updates...</LoadingMessage>
        ) : updates.length > 0 ? (
          <UpdatesGrid>
            {updates.map((update) => (
              <UpdateCard key={update.id}>
                <UpdateHeader>
                  <UpdateTitle>{update.title}</UpdateTitle>
                  <UpdateDate>{formatDate(update.date)}</UpdateDate>
                </UpdateHeader>
                <UpdateContent>
                  {update.content}
                  {update.linkedInUrl && (
                    <LinkedInLink 
                      href={update.linkedInUrl} 
                      target="_blank" 
                      rel="noopener noreferrer"
                    >
                      <FiLinkedin /> Find out more about her experience here
                    </LinkedInLink>
                  )}
                </UpdateContent>
              </UpdateCard>
            ))}
          </UpdatesGrid>
        ) : (
          <EmptyState>No updates available. Check back soon for new information.</EmptyState>
        )}
      </SectionContainer>
    </DashboardContainer>
  );
};

export default Dashboard;