import React, { createContext, useContext, useEffect, useState } from 'react';
import { User } from 'firebase/auth';
import { checkUserApproval, registerApprovalRequest, auth } from '../firebase/auth';

interface AuthContextProps {
  currentUser: User | null;
  isAuthenticated: boolean;
  isLoading: boolean;
  isApproved: boolean;
  isAnonymous: boolean;
  checkApproval: (email: string) => Promise<boolean>;
  requestApproval: (email: string) => Promise<boolean>;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isApproved, setIsApproved] = useState(false);
  const [isAnonymous, setIsAnonymous] = useState(false);
  
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setCurrentUser(user);
      setIsAuthenticated(!!user);
      setIsAnonymous(user?.isAnonymous || false);

      if (user && user.email) {
        // Regular users need to be in the approved list
        const approved = await checkUserApproval(user.email);
        setIsApproved(approved);
      } else {
        setIsApproved(false);
      }

      setIsLoading(false);
    });

    return unsubscribe;
  }, []);

  const checkApproval = async (email: string) => {
    const isUserApproved = await checkUserApproval(email);
    setIsApproved(isUserApproved);
    return isUserApproved;
  };

  const requestApproval = async (email: string) => {
    return registerApprovalRequest(email);
  };

  const value = {
    currentUser,
    isAuthenticated,
    isLoading,
    isApproved,
    isAnonymous,
    checkApproval,
    requestApproval
  };

  return (
    <AuthContext.Provider value={value}>
      {!isLoading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};